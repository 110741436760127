import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { toAbsoluteUrl } from "../../utils";
import "./communityDetails.scss";
import User from "../../assets/svg/User";
import CustomStar from "../Charity Details/TabsContent/Rating/StarRating";
import StarRatings from "react-star-ratings";
import CustomTab from "../../components/common/Tabs";
import Details from "./CommunityTabs/Details";
import Events from "./CommunityTabs/Events";
import CommunityMedia from "./CommunityTabs/Media";
import Member from "./CommunityTabs/Member";
import Share from "../../components/common/Share";
import { useDispatch, useSelector } from "react-redux";
import {
  createCommunityRatingAction,
  getCommunityDetails,
  joinCommunityHandler,
  leaveCommunityHandler,
  updateCommunityRatingAction,
} from "../../actions/community";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal/lib/components/Modal";
import Cancle from "../../assets/svg/cancle";
import { communityPostListAction } from "../../actions/post";
import { addToBasketAction } from "../../actions/cart";
import toast from "react-hot-toast";
import Telegram from "../../assets/svg/telegram";
import { BASKET_COUNT_S } from "../../constants/types";

const bread = [
  {
    name: "Community List",
    link: "/community",
    isActive: false,
  },
  {
    name: "Community Details",
    link: "/community_details",
    isActive: true,
  },
];

function CommunityDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const { communityDetails } = useSelector((state) => state.community);
  const { communityDetails: comunityInfo } = communityDetails;
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data._id;
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;

  const [readDesc, setReadDesc] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [communityRating, setCommunityRating] = useState(0);

  const tabs = [
    {
      heading: "Details",
      body: <Details isAssociated={comunityInfo?.isJoined} />,
    },
    {
      heading: "Events",
      body: comunityInfo?._id && (
        <Events id={comunityInfo?._id} from="community" />
      ),
    },
    {
      heading: "Media",
      body: (
        <CommunityMedia
          images={comunityInfo?.mediaImage}
          videos={comunityInfo?.mediaVideo}
        />
      ),
    },
    {
      heading: "Member",
      body: (
        <Member
          // members={comunityInfo?.communityMembers}
          communityId={comunityInfo?._id}
        />
      ),
    },
  ];

  useEffect(() => {
    setCommunityRating(comunityInfo?.userRating?.rating);
  }, [comunityInfo]);

  useEffect(() => {
    dispatch(getCommunityDetails(params.id));
    // eslint-disable-next-line
  }, []);

  const openModal = () => {
    setModalIsOpen((prev) => !prev);
  };

  const communityRatingHandler = (value) => {
    setCommunityRating(value);
  };

  const submitRatingModalHandler = () => {
    if (comunityInfo?.userRating?.rating) {
      dispatch(
        updateCommunityRatingAction(comunityInfo?.userRating?._id, {
          rating: communityRating,
        })
      ).then(() => dispatch(getCommunityDetails(params.id)));
      setModalIsOpen((prev) => !prev);

      return;
    }
    let payload;
    if (comunityInfo?.causeId) {
      payload = {
        userId: userId,
        // causeId: comunityInfo?.causeId,
        communityId: comunityInfo?._id,
        rating: communityRating,
      };
    } else {
      payload = {
        userId: userId,
        charityId: comunityInfo?.charityId,
        communityId: comunityInfo?._id,
        rating: communityRating,
      };
    }
    dispatch(createCommunityRatingAction(payload)).then(() =>
      dispatch(getCommunityDetails(params.id))
    );

    setModalIsOpen((prev) => !prev);
  };

  const joinCommunityBtnHandler = () => {
    if (!token) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }

    dispatch(
      joinCommunityHandler({
        charityId: comunityInfo?.charityId ? comunityInfo?.charityId : null,
        causeId: comunityInfo?.causeId ? comunityInfo?.causeId : null,
        communityId: comunityInfo?._id,
        userId: userId,
      })
    ).then(() => {
      dispatch(getCommunityDetails(params.id));
      // setAssociated(false)
    });
  };

  const leaveCommunityBtnHandler = () => {
    dispatch(leaveCommunityHandler(comunityInfo?.joinMembers?._id)).then(() => {
      dispatch(getCommunityDetails(params.id));
      dispatch(
        communityPostListAction(
          comunityInfo?._id,
          { page: 1, limit: 1000 },
          false
        )
      );
    });
  };

  const handleBasketButtonClick = (e) => {
    e.stopPropagation();
    if (!userId) {
      toast.error("Please login to add this community in the basket");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (comunityInfo?.isBasket) {
      navigate("/basket");
      return;
    }
    const payload = {
      userId,
      basket: [
        {
          communityId: comunityInfo?._id,
          type: "community",
        },
      ],
    };

    dispatch(addToBasketAction(payload))
      .then(() => {
        dispatch({
          type: BASKET_COUNT_S,
          payload: basketCount + 1,
        });
        toast.success("Community added to basket successfully.");
        dispatch(getCommunityDetails(params.id));
      })
      .catch(() => toast.error("Something went wrong"));
  };

  const onMessageBtnClick = (e) => {
    e.stopPropagation();
    navigate("/chat", {
      state: {
        from: "community",
        sendToId: `${
          comunityInfo?.mainId ? comunityInfo?.mainId : comunityInfo?.charityId
        }`,
        type: `${comunityInfo?.mainId ? "admin" : "charity"}`,
        name: `${
          comunityInfo?.charityName
            ? comunityInfo?.charityName
            : comunityInfo?.causeName
        }`,
      },
    });
  };

  return (
    <>
      <section className="communityDetail">
        <div className="container">
          <div className="breadcrumbscommunityDetails">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <section className="communityDetailsWrap">
            <div className="communitySidebar">
              {/* detil-card */}
              <div className="detailsCard">
                <figure className="detailsFigure">
                  {comunityInfo?.logo ? (
                    <img
                      src={mediaURL + "communityLogo/" + comunityInfo?.logo}
                      alt="community"
                    />
                  ) : (
                    <img
                      src={toAbsoluteUrl("/images/placeholder.png")}
                      alt="placeholder"
                    />
                  )}
                </figure>
                <div className="communityName">
                  <h1>{comunityInfo?.communityName}</h1>
                  <div className="communityStarWrap">
                    <div className="communityStar">
                      <CustomStar
                        rating={
                          comunityInfo?.ratingAvg ? comunityInfo?.ratingAvg : 0
                        }
                        numberOfStars={5}
                        starDimension="16px"
                        starSpacing="3px"
                        color="#FFC403"
                      />
                    </div>
                    <span>{comunityInfo?.countOfRating} ratings</span>
                  </div>
                </div>
                <div className="cardUser">
                  <ul>
                    <li>
                      <User />
                      <h5>
                        {comunityInfo?.charityName
                          ? comunityInfo?.charityName
                          : comunityInfo?.causeName}
                      </h5>
                    </li>
                    <li>
                      <User />
                      <h5>
                        {comunityInfo?.charityName ? "Nonprofit" : "Cause"}
                      </h5>
                    </li>
                  </ul>
                  {comunityInfo?.isJoined ? (
                    <button
                      className="telegramButtons"
                      onClick={onMessageBtnClick}
                    >
                      <Telegram />
                    </button>
                  ) : null}
                </div>
                {!comunityInfo?.isJoined ? (
                  <div className="notAssociatedDetailsButton">
                    <button onClick={handleBasketButtonClick}>
                      {!comunityInfo?.isBasket
                        ? "Add To Basket"
                        : "Go To Basket"}
                    </button>
                    <button onClick={joinCommunityBtnHandler}>
                      Join Community
                    </button>
                  </div>
                ) : (
                  <div className="associatedDetailsButtons">
                    <button
                      onClick={handleBasketButtonClick}
                      className="detailsBasket"
                    >
                      {!comunityInfo?.isBasket
                        ? "Add To Basket"
                        : "Go To Basket"}
                    </button>
                    <button
                      className="leaveButton"
                      onClick={leaveCommunityBtnHandler}
                    >
                      Leave Community
                    </button>
                    <button onClick={openModal} className="rateButton">
                      Rate Community
                    </button>
                  </div>
                )}
              </div>

              {/* research */}
              <div className="research">
                <h3>{comunityInfo?.communityName}</h3>
                
                <p
                className="newline"
            dangerouslySetInnerHTML={{ __html: comunityInfo?.shortDescrp }}
           
          ></p>
                <div className="member">
                  <div className="member-list">
                    <div className="member-images">
                      {comunityInfo?.mutualFrdImgList?.map((image, ind) => (
                        <figure key={image?._id}>
                          {image?.image ? (
                            <img
                              src={`${mediaURL}userProfilePic/${image?.image}`}
                              alt="user"
                            />
                          ) : (
                            <img
                              src={toAbsoluteUrl("/images/placeholder.png")}
                              alt="placeholder"
                            />
                          )}
                        </figure>
                      ))}
                    </div>
                    <span className="member-count">
                      {comunityInfo?.communityMembers?.length} Members
                    </span>
                  </div>
                  <button
                    className={
                      comunityInfo?.shortDescrp?.length < 155 ? "hideBtn" : ""
                    }
                    onClick={() => setReadDesc((prev) => !prev)}
                  >
                    {readDesc ? "Read More" : "Read Less"}
                  </button>
                </div>
              </div>

              {/* share */}
              <Share />
            </div>
            <div className="communityContent">
              <CustomTab className="communityTab" data={tabs} />
            </div>
          </section>
        </div>
      </section>

      <Modal
        className={`custom-modal communityModal`}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => {
          setModalIsOpen((prev) => !prev);
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-header">
          <h3>Rate A community</h3>
          <button
            onClick={() => {
              setModalIsOpen((prev) => !prev);
            }}
          >
            <Cancle />
          </button>
        </div>
        <div className="modal-body">
          <h3>
            Let us know what you feel about our service.Your sharing is valuable
            for us.
          </h3>
          <div className="communityRating">
            <StarRatings
              rating={communityRating}
              starDimension="56px"
              starSpacing="15px"
              starRatedColor="#FFC403"
              starHoverColor="#FFC403"
              initialRating={communityRating}
              changeRating={communityRatingHandler}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="submit"
            onClick={submitRatingModalHandler}
            disabled={communityRating === 0 ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}

export default CommunityDetails;
