import React from "react";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { doImageDelete, doUploadImage } from "../../../actions/charityRegister";
import { toAbsoluteUrl } from "../../../utils";
import Delete from "../../svg/delete";
import Edit from "../../svg/edit";
import "./upload.scss";

const ImgUpload = ({
  multiple,
  setImages,
  imgs,
  images,
  oldImages,
  setOldImages,
  setDeleteImage,
  setImg,
  moduleName,
  maxNumber,
  image,
  location,
  isPost = false,
  postPage,
  callUploadAPI,
}) => {
  const dispatch = useDispatch();
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    if (callUploadAPI) {
      if (multiple) {
        imageList.map((image) => {
          if (!image?.name) {
            const data = { image: [image], moduleName: moduleName };
            dispatch(doUploadImage(data)).then((res) => {
              setOldImages("");
              setImg((prev) => [
                ...prev,
                {
                  name: res.data.name,
                  url: mediaURL + moduleName + "/" + res.data.name,
                },
              ]);
            });
          }
          return "";
        });
        if (oldImages?.length) {
          setImg((prev) => [...prev, ...oldImages]);
        }
      } else {
        const data = { image: [imageList[0]], moduleName: moduleName };
        dispatch(doUploadImage(data)).then((res) => {
          setImg(res.data.name);
          setOldImages("");
        });
      }
    }
  };
  const handleOldDelete = (e, image) => {
    e.preventDefault();
    // dispatch(doImageDelete(data)).then(() => {
    let filteredImages = oldImages.filter((o) => o !== image);
    setOldImages(filteredImages);
    setDeleteImage({ name: image?.name });
    // });
  };
  const handleDelete = (e, image) => {
    e.preventDefault();
    const data = {
      fileName: image?.name,
      moduleName: moduleName,
    };
    dispatch(doImageDelete(data)).then(() => {
      let filteredImages = imgs.filter((o) => o !== image);
      setImg(filteredImages);
    });
  };
  return (
    <>
      <ImageUploading
        multiple={multiple}
        value={callUploadAPI ? imgs : images}
        onError={(e) => {
          if (e?.maxFileSize === true) {
            toast.error(
              "Image file will support JPG PNG file size should be less than 2 mb"
            );
          } else if (e?.maxNumber === true) {
            toast.error("You can't upload more than five images");
          }
        }}
        onChange={onChange}
        maxFileSize={2000000}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "png", "jpeg", "jfif"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your building UI
          <>
            {!location && (
              <div className="upload__image-wrapper">
                {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                <div className="img-map-box">
                  {oldImages?.length && multiple ? (
                    oldImages?.map((image, index) => (
                      <div
                        key={postPage ? image?.url : image.data_url}
                        className="image-item"
                      >
                        <img
                          src={`${postPage ? image?.url : image.data_url}`}
                          alt=""
                          width="100"
                        />
                        <div className="image-item__btn-wrapper">
                          <button
                            className="img-btn"
                            onClick={() => onImageUpdate(index)}
                          >
                            {" "}
                            <Edit />{" "}
                          </button>
                          <button
                            className="img-btn"
                            onClick={(e) => handleOldDelete(e, image)}
                          >
                            <Delete />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                  {oldImages?.length && !multiple && imageList.length === 0 ? (
                    oldImages?.map(
                      (image, index) =>
                        image && (
                          <div key={image.data_url} className="image-item">
                            <img src={`${image.data_url}`} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                type="button"
                                className="img-btn"
                                onClick={() => onImageUpdate(index)}
                              >
                                {" "}
                                <Edit />{" "}
                              </button>
                              <button
                                type="button"
                                className="img-btn"
                                onClick={(e) => handleOldDelete(e, image)}
                              >
                                <Delete />
                              </button>
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div></div>
                  )}

                  {imageList?.map((image, index) => (
                    <div
                      key={callUploadAPI ? image?.url : image.data_url}
                      className="image-item"
                    >
                      <img
                        src={callUploadAPI ? image?.url : image.data_url}
                        // src={image.data_url}
                        alt=""
                        width="100"
                      />
                      <div className="image-item__btn-wrapper">
                        <button
                          type="button"
                          className="img-btn"
                          onClick={() => onImageUpdate(index)}
                          title="edit"
                        >
                          {" "}
                          <Edit />{" "}
                        </button>
                        <button
                          type="button"
                          className="img-btn"
                          // onClick={() => onImageRemove(index)}
                          onClick={(e) =>
                            callUploadAPI
                              ? handleDelete(e, image)
                              : onImageRemove(index)
                          }
                          title="delete"
                        >
                          <Delete />
                        </button>
                      </div>
                    </div>
                  ))}
                  {!isPost ? (
                    <button
                      type="button"
                      className={isPost ? "" : "upload-btn"}
                      style={isDragging ? { color: "red" } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      {isPost ? "Select File" : "+"}
                    </button>
                  ) : (
                    <div
                      className="editUpload"
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <div className="uploading">
                        <figure>
                          <img
                            src={toAbsoluteUrl("/images/uploading.svg")}
                            alt="uploading"
                          />
                        </figure>
                        <div className="fileContent">
                          <h4>Select JPG, PNG file type</h4>
                          <p>Max file size is 10MB</p>
                        </div>

                        <button type="button">Select File</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {location === "profilePage" && (
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: "red" } : null}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <img src="./images/edit.svg" alt="edit" />
                </button>
                <div className="image-item">
                  {oldImages && (
                    <img
                      src={mediaURL + moduleName + "/" + oldImages}
                      alt="profile"
                      width="100"
                    />
                  )}
                  {image && (
                    <img
                      src={mediaURL + moduleName + "/" + image}
                      alt="profile"
                      width="100"
                    />
                  )}
                  {!image && !oldImages && (
                    <img
                      src={toAbsoluteUrl("/images/placeholder.png")}
                      alt="placeholder"
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </ImageUploading>
    </>
  );
};

export default ImgUpload;
