/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToBasketAction } from "../../../actions/cart";
import {
  joinCommunityHandler,
  leaveCommunityHandler,
} from "../../../actions/community";
import GoToBasket from "../../../assets/svg/goToBasket";
import Telegram from "../../../assets/svg/telegram";
import { BASKET_COUNT_S } from "../../../constants/types";
import { toAbsoluteUrl } from "../../../utils";
import "./communityCard.scss";

function CommunityCard(props) {
  const {
    community,
    classname,
    isAssociated,
    setData,
    page,
    limit,
    data,
    randerCharityListHandler,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const [args, setArgs] = useState({ page: 1 });
  const [isInBasket, setIsInBasket] = useState(community?.isBasket);

  useEffect(() => {
    if (page && limit) {
      setArgs({ ...args, limit: limit * page });
    }
  }, [page, limit]);

  const handleCardClick = (e, charities) => {
    e.preventDefault();
    navigate(`/community_details/${community._id}`, {
      state: { isAssociated: isAssociated ? false : true },
    });
  };

  const handleBasketButtonClick = (e) => {
    e.stopPropagation();
    if (!userId) {
      toast.error("Please login to add this community in the basket");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (isInBasket) {
      navigate("/basket");
      return;
    }
    const payload = {
      userId,
      basket: [
        {
          communityId: community?._id,
          type: "community",
        },
      ],
    };

    dispatch(addToBasketAction(payload))
      .then(() => {
        dispatch({
          type: BASKET_COUNT_S,
          payload: basketCount + 1,
        });
        toast.success("Community added to basket successfully.");
        setIsInBasket(true);
      })
      .catch(() => toast.error("Something went wrong"));
  };

  const leaveCommunityHandlerFun = (e) => {
    e.stopPropagation();
    if (!token) {
      toast.error("Please login to join this community");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (isAssociated) {
      dispatch(leaveCommunityHandler(community?.joinMembers?._id)).then(() => {
        let temp = data;
        let filteredData = temp.filter((comm) => comm._id !== community._id);
        if (filteredData?.length === limit - 1) {
          randerCharityListHandler(page + 1, limit - 1);
        }
        setData(filteredData);
        toast.success(`You leaved ${community?.communityName} community`);
      });
    } else {
      dispatch(
        joinCommunityHandler({
          charityId: community?.charityId ? community?.charityId : null,
          causeId: community?.causeId ? community?.causeId : null,
          communityId: community?._id,
          userId: userId,
        })
      ).then(() => {
        setData((prev) => prev.filter((comm) => comm._id !== community._id));
        toast.success(`You joied ${community?.communityName} community`);
      });
    }
  };

  const onMessageBtnClick = (e) => {
    e.stopPropagation();
    navigate("/chat", {
      state: {
        from: "community",
        sendToId: `${
          community?.mainId ? community?.mainId : community?.charityId
        }`,
        type: `${community?.mainId ? "admin" : "charity"}`,
        name: `${community?.charityName ? community?.charityName : "Admin"}`,
      },
    });
  };

  return (
    <>
      <div className="communityCard" onClick={(e) => handleCardClick(e)}>
        <div className="communityImage">
          <figure className="mainImage imageBorder">
            {community?.logo && community?.logo !== "string" ? (
              <img
                src={`${mediaURL}communityLogo/${community?.logo}`}
                alt="commnity"
              />
            ) : (
              <img
                src={toAbsoluteUrl("/images/placeholder.png")}
                alt="placeholder"
              />
            )}
          </figure>
        </div>
        <div className="communityListContent">
          <h4>{community?.communityName}</h4>
          <div className="communityDetails">
            <h5>
              {community?.charityName
                ? community?.charityName
                : community?.causeName}
            </h5>
            <ul>
              <li>
                <img
                  src={toAbsoluteUrl("/images/communityLogo.png")}
                  alt="logo"
                ></img>
              </li>
              <li>
                <span>{community?.charityName ? "nonprofit" : "cause"}</span>
              </li>
            </ul>
          </div>
          <div className="member">
            <div className="member-list">
              <div className="member-images">
                {community?.mutualFrdImgList?.map((image) => (
                  <figure key={image?.image}>
                    {image?.image ? (
                      <img
                        src={`${mediaURL}userProfilePic/${image?.image}`}
                        alt="user"
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl("/images/placeholder.png")}
                        alt="placeholder"
                      />
                    )}
                  </figure>
                ))}
              </div>
              <span className="member-count">
                {community?.communityMemberCount} Members
              </span>
            </div>
          </div>
          <div className={`${classname} communityButtons`}>
            <button
              className={`communityBasket ${
                isInBasket ? "associateCommunityBasket" : ""
              }`}
              onClick={handleBasketButtonClick}
            >
              <GoToBasket />
            </button>
            <button className="joinButtons" onClick={leaveCommunityHandlerFun}>
              {props?.isAssociated ? (
                <img
                  src={toAbsoluteUrl("/images/community-leave.png")}
                  alt="community-leave"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/community-add.png")}
                  alt="community-join"
                />
              )}
            </button>
            {isAssociated && (
              <button className="communityBasket" onClick={onMessageBtnClick}>
                <Telegram />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityCard;
