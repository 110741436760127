/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Share from "../../components/common/Share";
import { toAbsoluteUrl } from "../../utils";
import "../Post/post.scss";
import "./postDetails.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux";
import { sharePostAction } from "../../actions/post";
import PostCard from "../../components/common/Post";

const bread = [
  {
    name: "Post",
    link: "/post",
    isActive: false,
  },
  {
    name: "Post Details",
    link: "/post-details",
    isActive: true,
  },
];

const posting = [
  {
    id: 1,
    attendingCount: 18,
    countType: "Requested",
  },
  {
    id: 2,
    attendingCount: 4,
    countType: "Approved",
  },
  {
    id: 3,
    attendingCount: 3,
    countType: "Total Feed",
  },
];

function PostDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const { postDetails } = useSelector((state) => state.post);

  const [userDetails, setUserDetails] = useState();
  const [postInfo, setPostInfo] = useState();

  useEffect(() => {
    if (postDetails) {
      if (
        postDetails?.PostDetails?.createdBy === 1 ||
        postDetails?.PostDetails?.createdBy === 5
      ) {
        setPostInfo({
          ...postDetails?.PostDetails,
          userDetails: postDetails?.userDetails,
        });
        setUserDetails(postDetails?.userDetails);
      } else if (postDetails?.PostDetails?.createdBy === 3) {
        setPostInfo({
          ...postDetails?.PostDetails,
          strategicPartnerDetails: postDetails?.strategicPartnerDetails,
        });
      } else if (postDetails?.charityDetails?.charityName) {
        setPostInfo({
          ...postDetails?.PostDetails,
          charityDetails: postDetails?.charityDetails,
        });
      } else if (postDetails?.causeDetails) {
        setPostInfo({
          ...postDetails?.PostDetails,
          causeDetails: postDetails?.causeDetails,
        });
      }
    }
  }, [postDetails]);

  useEffect(() => {
    dispatch(sharePostAction(params?.id));
  }, []);

  const setProfileDetails = () => {
    if (
      postDetails?.PostDetails?.createdBy === 1 ||
      postDetails?.PostDetails?.createdBy === 5
    ) {
      return (
        <div className="accountPost">
          <div className="postAccountDetails">
            <figure>
              {userDetails?.profileImage ? (
                <img
                  src={mediaURL + "userProfilePic/" + userDetails?.profileImage}
                  alt="user"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
            </figure>
            <h3>{userDetails?.firstName + " " + userDetails?.lastName}</h3>
          </div>
          <div className="userAccountCount">
            <div key={posting.id} className="attendingPostCount">
              <span>{userDetails?.totalFrd}</span>
              <h4>Total Friends</h4>
            </div>
            <div key={posting.id} className="attendingPostCount">
              <span>{userDetails?.totalFedd}</span>
              <h4>Total Feeds</h4>
            </div>
          </div>
        </div>
      );
    } else if (postDetails?.PostDetails?.createdBy === 3) {
      return (
        <div className="accountPost">
          <div className="postAccountDetails">
            <figure>
              {postDetails?.strategicPartnerDetails?.strategicProfile ? (
                <img
                  src={
                    mediaURL +
                    "strategicLogo/" +
                    postDetails?.strategicPartnerDetails?.strategicProfile
                  }
                  alt="user"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
            </figure>
            <h3>{postDetails?.strategicPartnerDetails?.strategicName}</h3>
          </div>
        </div>
      );
    } else if (postDetails?.charityDetails) {
      return (
        <div className="accountPost">
          <div className="postAccountDetails">
            <figure>
              {postDetails?.charityDetails?.charityProfile ? (
                <img
                  src={
                    mediaURL +
                    "charityLogo/" +
                    postDetails?.charityDetails?.charityProfile
                  }
                  alt="user"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
            </figure>
            <h3>{postDetails?.charityDetails?.charityName}</h3>
          </div>
        </div>
      );
    } else {
      return (
        <div className="accountPost">
          <div className="postAccountDetails">
            <figure>
              {postDetails?.causeDetails?.causeProfile ? (
                <img
                  src={
                    mediaURL +
                    "causeLogo/" +
                    postDetails?.causeDetails?.causeProfile?.name
                  }
                  alt="user"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
            </figure>
            <h3>{postDetails?.causeDetails?.causeName}</h3>
          </div>
        </div>
      );
    }
  };

  const showActionHandler = (postDetails) => {
    return postDetails?.userDetails?.userId !== userId;
  };

  return (
    <>
      <section className="post">
        <div className="container">
          <div className="breadcrumbsPost">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <div className="postWrap">
            <div className="postSidebar">
              {setProfileDetails()}
              <Share />
            </div>
            <div className="postContent">
              <div className="detailsHeader">
                <h2>Post</h2>
              </div>
              <PostCard
                post={postInfo}
                from="postDetailModule"
                showAction={showActionHandler(postInfo)}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PostDetails;
