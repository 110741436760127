/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Player } from "video-react";
import {
  communityPostListAction,
  creatCommentReplyAction,
  createCommentAction,
  deletePostAction,
  doUploadVideo,
  editPostAction,
  getUserPostListAction,
  hidePostAction,
  joinCommunityPostListAction,
  likeUnlikeCommentAction,
  likeUnlikePostAction,
  postListAction,
  renderCommentAction,
  reportPostAction,
  sharePostAction,
} from "../../../actions/post";
import Comment from "../../../assets/svg/coment";
import Heart from "../../../assets/svg/heart";
import Share from "../../../assets/svg/share";
import { getVideoUrl, toAbsoluteUrl, isUserLoggedIn } from "../../../utils";
import CustomModal from "../Modal";
import PostCard from "../PostCard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Field, Form } from "react-final-form";
import { rendertextarea } from "../../forms";
import ImgUpload from "../img-upload";
import { RenderVideoField } from "../Video-upload";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  sendFriendRequestAction,
  unfriendFriendAction,
} from "../../../actions/friend";
import { useDetectOutsideClick } from "../../../Hooks/useDetectOutsideClick";
import { employeePostAction } from "../../../actions/strategic";
import Linkify from "react-linkify";
import { doUploadImage } from "../../../actions/charityRegister";

const Post = ({
  post,
  communityId,
  isMyPost,
  from,
  isLoading,
  setisLoading,
  webUserId,
  showAction,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const user = JSON.parse(localStorage.getItem("webUser"));
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const profilePic = JSON.parse(localStorage.getItem("profilePic"));
  const employeeId = JSON.parse(localStorage.getItem("employeeId"));
  const modalRef = useRef(null);

  const [isMoreOpen, setIsMoreOpen] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [postType, setPostType] = useState("");
  const [active, setActive] = useState("public");
  const [communityCommentList, setCommunityCommentList] = useState([]);
  const [readDesc, setReadDesc] = useState(true);
  const [currPostDetails, setCurrPostDetails] = useState("");
  const [isLike, setIsLike] = useState(post?.islike);

  //comment state
  const [isCommentOpen, setIsCommentOpen] = useState(null);
  const [isReplyCommentBoxOpen, setIsReplyCommentBoxOpen] = useState();
  const [isReplyReplyCommentBoxOpen, setisReplyReplyCommentBoxOpen] =
    useState();
  const [commentText, setCommentText] = useState();
  const [replyText, setReplyText] = useState();
  const [replyReplyText, setReplyReplyText] = useState();
  const [showMoreComment, setShowMoreComment] = useState(0);

  //image video state
  const [image, setImage] = useState([]);
  const [postImages, setPostImages] = useState([]);
  const [oldPostImages, setOldPostImages] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);

  const [videoArray, setVideoArray] = useState([]);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);
  const [deletedVideoArray, setDeletedVideoArray] = useState([]);

  const pagination = {
    clickable: true,
  };
  const dropdownOpen = () => {
    setIsMoreOpen((prev) => !prev);
  };

  useDetectOutsideClick(modalRef, () => setIsMoreOpen(false));

  const reportPostHandler = (postId) => {
    if (!isUserLoggedIn()) {
      navigate(`/signin?${window.location.pathname}`);
      return;
    }

    dispatch(reportPostAction(postId)).then((res) => {
      setIsMoreOpen("");
      toast.success(res.message);
    });
  };

  const hidePostHandler = (postId) => {
    if (!isUserLoggedIn()) {
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    let payload = {
      postId,
    };
    if (communityId) {
      payload = { ...payload, communityId };
    }
    dispatch(hidePostAction(payload)).then((res) => {
      if (from === "postDetailModule") {
        dispatch(sharePostAction(post?._id));
        return;
      }
      if (from === "userProfile") {
        dispatch(getUserPostListAction(post?.userDetails?._id));
        return;
      }
      if (from === "employeePost") {
        const payload = { page: 1, limit: 20 };
        if (isMyPost) payload.isEmployee = "true";
        dispatch(
          employeePostAction(payload, employeeId, isMyPost, false, true)
        );
        return;
      }
      if (communityId) {
        dispatch(
          communityPostListAction(
            communityId,
            { page: 1, limit: 20 },
            isMyPost,
            true
          )
        );
      } else {
        if (isMyPost === undefined) {
          dispatch(
            joinCommunityPostListAction(
              { _id: userId, page: 1, limit: 20 },
              false,
              false
            )
          );
        } else
          dispatch(
            postListAction({ page: 1, limit: 20 }, isMyPost, false, false)
          );
      }
      toast.success(res.message);
    });
  };

  const friendUnFriendDonor = (donorId, isFriend) => {
    if (isFriend === true) {
      dispatch(unfriendFriendAction({ _id: donorId }))
        .then((res) => {
          toast.success(res.message);
          if (from === "postModule") {
            dispatch(
              joinCommunityPostListAction(
                { _id: userId, page: 1, limit: 20 },
                false,
                false
              )
            );
          }
          if (from === "employeePost") {
            const payload = { page: 1, limit: 20 };
            if (isMyPost) payload.isEmployee = "true";
            dispatch(
              employeePostAction(payload, employeeId, isMyPost, false, true)
            );
            return;
          }
          if (from === "postDetailModule") {
            dispatch(sharePostAction(post?._id));
            return;
          }
          if (from === "userProfile") {
            dispatch(getUserPostListAction(post?.userDetails?._id));
            return;
          }
          if (communityId) {
            dispatch(
              communityPostListAction(
                communityId,
                { page: 1, limit: 20 },
                isMyPost,
                true
              )
            );
          } else {
            if (isMyPost === undefined) {
              dispatch(
                joinCommunityPostListAction(
                  { _id: userId, page: 1, limit: 20 },
                  false,
                  false
                )
              );
            } else
              dispatch(
                postListAction({ page: 1, limit: 20 }, isMyPost, false, false)
              );
          }
        })
        .catch((res) => toast.error(res.message));
    } else {
      dispatch(sendFriendRequestAction({ friendId: donorId }))
        .then((res) => {
          if (communityId) {
            dispatch(
              communityPostListAction(
                communityId,
                { page: 1, limit: 20 },
                isMyPost,
                true
              )
            );
          } else {
            if (isMyPost === undefined) {
              dispatch(
                joinCommunityPostListAction(
                  { _id: userId, page: 1, limit: 20 },
                  false,
                  false
                )
              );
            } else
              dispatch(
                postListAction({ page: 1, limit: 20 }, isMyPost, false, false)
              );
          }
          toast.success(res.message);
        })
        .catch((res) => toast.error(res.message));
    }
  };

  const editModal = (postDetails) => {
    setVideoArray(postDetails?.video);
    setOldPostImages(postDetails?.image);
    setCurrPostDetails(postDetails);
    setEditIsOpen((prev) => !prev);
    if (postDetails?.type === 1) {
      setPostType("photo");
    } else if (postDetails?.type === 2) {
      setPostType("video");
    } else if (postDetails?.type === 3) {
      setPostType("article");
    }
  };

  const openModal = (postDetails) => {
    setCurrPostDetails(postDetails);
    setModalIsOpen((prev) => !prev);
  };

  const postCommentHandler = (
    postInfo,
    isReply,
    commentId = null,
    keepOpen = false
  ) => {
    let commentContent;

    if (replyReplyText) {
      commentContent = replyReplyText;
    } else if (replyText) {
      commentContent = replyText;
    } else commentContent = commentText;

    let payload = {
      postId: postInfo?._id,
      userId,
      comment: commentContent,
      isReply,
    };
    if (isReply) {
      payload = { ...payload, commentId: commentId };
      dispatch(creatCommentReplyAction(payload)).then((res) => {
        renderComments();
        setCommentText("");
        setReplyText("");
        setReplyReplyText("");
        setisReplyReplyCommentBoxOpen(null);
      });
      return;
    }
    dispatch(createCommentAction(payload)).then((res) => {
      renderComments();
      setCommentText("");
      setReplyText("");
      setReplyReplyText("");
      setisReplyReplyCommentBoxOpen(null);
    });
  };

  const renderCommentsHandler = (showMore = false) => {
    if (showMoreComment === 0 || showMoreComment === 2) {
      setShowMoreComment(1);
    } else setShowMoreComment(2);
  };

  useEffect(() => {
    if (showMoreComment === 1 || showMoreComment === 2) {
      const payload = {
        page: 1,
        limit: showMoreComment === 1 ? 10000000 : 3,
      };
      dispatch(renderCommentAction(post?._id, payload)).then((res) => {
        setCommunityCommentList(res.data);
      });
    }
  }, [showMoreComment]);

  const showCommentReplyReplyBoxHandler = (commentId) => {
    if (!isReplyReplyCommentBoxOpen) {
      setisReplyReplyCommentBoxOpen(commentId);
    } else {
      setisReplyReplyCommentBoxOpen(null);
    }
  };

  const renderComments = () => {
    const payload = {
      page: 1,
      limit: showMoreComment === 1 ? 10000000 : 3,
    };

    dispatch(renderCommentAction(post?._id, payload)).then((res) => {
      setCommunityCommentList(res.data);
    });
  };

  const likeBtnClickHandler = (postId, isLiked) => {
    if (!isUserLoggedIn()) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    dispatch(likeUnlikePostAction(postId, !isLike ? "like" : "unlike")).then(
      () => {
        setIsLike((prev) => !prev);
      }
    );
  };

  const showCommentHandler = (postId) => {
    if (isCommentOpen === null) {
      const payload = {
        page: 1,
        limit: 20,
      };
      dispatch(renderCommentAction(post?._id, payload)).then((res) => {
        setCommunityCommentList(res.data);
        setIsCommentOpen(post?._id);
      });
    } else setIsCommentOpen(null);
  };

  const likeUnlikeCommentHandler = (commentId, postId) => {
    if (!isUserLoggedIn()) {
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    dispatch(likeUnlikeCommentAction(commentId)).then(() => {
      const payload = {
        page: 1,
        limit: showMoreComment === 1 ? 10000000 : 3,
      };
      dispatch(renderCommentAction(post?._id, payload)).then((res) => {
        setCommunityCommentList(res.data);
      });
    });
  };

  const showCommentReplyBoxHandler = (commentId) => {
    if (!isReplyCommentBoxOpen) {
      setIsReplyCommentBoxOpen(commentId);
    } else {
      setIsReplyCommentBoxOpen(null);
    }
  };

  const deletePostHandler = () => {
    if (!isUserLoggedIn()) {
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    dispatch(deletePostAction(currPostDetails?._id, true)).then(() => {
      const payload = { page: 1, limit: 20 };
      if (communityId) {
        dispatch(communityPostListAction(communityId, payload, isMyPost, true));
      } else if (from === "employeePost") {
        if (isMyPost) payload.isEmployee = "true";
        dispatch(
          employeePostAction(payload, employeeId, isMyPost, false, true)
        );
      } else if (from === "userProfile") {
        dispatch(getUserPostListAction(webUserId));
      } else {
        if (isMyPost === undefined) {
          dispatch(
            joinCommunityPostListAction(
              { _id: userId, ...payload },
              false,
              false
            )
          );
        } else dispatch(postListAction(payload, isMyPost, false, true));
      }
      setCurrPostDetails("");
      setModalIsOpen((prev) => !prev);
    });
  };

  const radioButtonHandler = (e) => {
    setActive(e.target.name);
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      toast.success("Post link copied ");
    } catch (err) {
      toast.error("Oops, unable to copy");
    }

    document.body.removeChild(textArea);
  };

  const sharePostHandler = (postId) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(
        `${window.location.origin}${process.env.PUBLIC_URL}/post-details/${postId}`
      );
      return;
    }
    navigator?.clipboard
      ?.writeText(
        `${window.location.origin}${process.env.PUBLIC_URL}/post-details/${postId}`
      )
      .then(() => {
        toast.success("Post link copied ");
      });
  };

  const editPostFun = (data) => {
    dispatch(editPostAction(currPostDetails?._id, data)).then(() => {
      setisLoading(true);
      if (communityId) {
        dispatch(
          communityPostListAction(
            communityId,
            { page: 1, limit: 20 },
            isMyPost,
            true
          )
        )
          .then(() => setisLoading(false))
          .catch(() => setisLoading(false));
      } else if (from === "employeePost") {
        let payload = {
          page: 1,
          limit: 20,
        };
        if (isMyPost) {
          payload = { ...payload, isEmployee: "true" };
        }
        dispatch(employeePostAction(payload, employeeId, isMyPost, false))
          .then(() => setisLoading(false))
          .catch(() => setisLoading(false));
      } else if (from === "userProfile") {
        dispatch(getUserPostListAction(webUserId, true))
          .then(() => setisLoading(false))
          .catch(() => setisLoading(false));
      } else {
        dispatch(postListAction({ page: 1, limit: 20 }, isMyPost, false, false))
          .then(() => setisLoading(false))
          .catch(() => setisLoading(false));
      }
      setEditIsOpen((prev) => !prev);
      setImage([]);
      setPostImages([]);
    });
  };

  const uploadVideoFiles = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName: "postImages",
          video: file,
        })
      )
    );
  };

  const formSubmitHandler = (value) => {
    let data = {
      type: 3,
      userId,
      description: value?.postContent ? value?.postContent : "",
      isPrivate: active === "public" ? false : true,
      hidePost: false,
      _id: currPostDetails?._id,
      image: [],
      removeImage: [],
      video: [],
      removeVideo: [],
    };
    if (communityId) {
      data = { ...data, communityId };
    }
    if (from === "employeePost") {
      data = {
        ...data,
        strategicPartnerId: employeeId,
        employeeId: userId,
        createdBy: 5,
      };
    }
    if (postType === "photo") {
      let imgArr = [];
      let len = postImages.length;
      if (len === 0 && oldPostImages?.length === 0) {
        toast.error("Please attach atleast one image");
        return;
      }
      if (len === 0) {
        data = {
          ...data,
          type: 1,
          image: imgArr,
          removeImage: [...deleteImage],
        };
        editPostFun(data);
        return;
      }
      postImages.forEach((image) => {
        if (!image?.name) {
          const payload = { image: [image], moduleName: "postImages" };
          dispatch(doUploadImage(payload)).then((res) => {
            len = len - 1;
            imgArr.push({
              name: res.data.name,
              url: mediaURL + "postImages/" + res.data.name,
            });
            setImage((prev) => [
              ...prev,
              {
                name: res.data.name,
                url: mediaURL + "postImages/" + res.data.name,
              },
            ]);
            if (len === 0) {
              data = {
                ...data,
                type: 1,
                image: imgArr,
                removeImage: [...deleteImage],
              };
              editPostFun(data);
            }
          });
        }
        return "";
      });
    } else if (postType === "video") {
      if (videoArray.length === 0 && videoSourceFiles.length === 0) {
        toast.error("Please attach a video");
        return;
      }
      const videoPayload = [];
      Promise.allSettled(uploadVideoFiles()).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            videoPayload.push({
              name: value.value?.data?.name,
              url: getVideoUrl(mediaURL, "postImages", value.value?.data?.name),
              thumbnail: value.value?.data.thumbnail,
            });
          }
          return null;
        });
        data = {
          ...data,
          type: 2,
          video: videoPayload,
          image: [],
          removeVideo: deletedVideoArray,
        };
        editPostFun(data);
      });
    } else {
      editPostFun(data);
    }
  };

  const required = (value) => {
    if (postType === "article") {
      if (!value) {
        return "Please enter text";
      }
      if (value && !value.trim()) {
        return "Please enter valid text";
      }
      // return (value || value.trim() ? undefined : 'Required')
      return undefined;
    }
    return undefined;
  };

  const getPostProfile = () => {
    if (post?.charityDetails?.charityProfile) {
      return post?.charityDetails?.charityProfile ? (
        <img
          src={mediaURL + "charityLogo/" + post?.charityDetails?.charityProfile}
          alt="leader"
        ></img>
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    } else if (post?.userDetails) {
      return post?.userDetails?.profileImage ? (
        <img
          src={mediaURL + "userProfilePic/" + post?.userDetails?.profileImage}
          alt="leader"
        ></img>
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    } else if (post?.strategicPartnerDetails) {
      return post?.strategicPartnerDetails?.profileImage ? (
        <img
          src={
            mediaURL +
            "strategicLogo/" +
            post?.strategicPartnerDetails?.profileImage
          }
          alt="leader"
        ></img>
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    } else {
      return post?.causeDetails?.causeProfile?.url ||
        post?.causeDetails?.images?.url ? (
        <img
          src={
            post?.causeDetails?.causeProfile?.url
              ? post?.causeDetails?.causeProfile?.url
              : post?.causeDetails?.images?.url
          }
          alt="cause"
        ></img>
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    }
  };

  const getPostUserName = () => {
    if (post?.charityDetails?.charityName) {
      return post?.charityDetails?.charityName;
    } else if (post?.userDetails?.firstName) {
      return post?.userDetails
        ? post?.userDetails?.firstName + " " + post?.userDetails?.lastName
        : post?.charityName;
    } else if (post?.strategicPartnerDetails) {
      return post?.strategicPartnerDetails?.firstName
        ? post?.strategicPartnerDetails?.firstName +
            " " +
            post?.strategicPartnerDetails?.lastName
        : post?.strategicPartnerDetails?.strategicName;
    } else {
      return post?.causeDetails?.causename
        ? post?.causeDetails?.causename
        : post?.causeDetails?.causeName;
    }
  };

  const handlecommentimage = (val) => {
    switch (val) {
      case "Charity":
        return "charityLogo/";
      case "Donor":
        return "userProfilePic/";
      case "Rosi Giving":
        return "adminImages/";
      case "Strategic":
        return "strategicLogo/";
      default:
        break;
    }
  };

  const postActions = () => {
    if (post?.userDetails && userId !== post?.userDetails?._id) {
      return (
        <ul className="profileDropdown">
          {from !== "postDetailModule" && (
            <li onClick={() => hidePostHandler(post?._id)}>Hide Post</li>
          )}
          {(post?.userDetails?.isFriend ||
            post?.isFriend ||
            post?.userFriendsDetails?.[0]?.isFriend === 2) && (
            <li onClick={onMessageBtnClick}>Send Message</li>
          )}
          <li onClick={() => reportPostHandler(post?._id)}>Report Post</li>
          {post?.userDetails?.isFriend ||
          post?.isFriend ||
          post?.userFriendsDetails?.[0]?.isFriend === 2 ? (
            <li
              onClick={() => friendUnFriendDonor(post?.userDetails?._id, true)}
            >
              Unfriend Donor
            </li>
          ) : (
            <li
              onClick={() => friendUnFriendDonor(post?.userDetails?._id, false)}
            >
              Friend Donor
            </li>
          )}
        </ul>
      );
    } else if (
      post?.charityDetails ||
      post?.causeDetails ||
      post?.strategicPartnerDetails
    ) {
      return (
        <ul className="profileDropdown">
          <li onClick={() => reportPostHandler(post?._id)}>Report Post</li>
          {from !== "postDetailModule" && (
            <li onClick={() => hidePostHandler(post?._id)}>Hide Post</li>
          )}
          {(post?.charityDetails && post?.isJoined) ||
            (post?.strategicPartnerDetails && (
              <li onClick={onMessageBtnClick}>Send Message</li>
            ))}
        </ul>
      );
    } else {
      return (
        <ul className="profileDropdown">
          <li onClick={() => editModal(post)}>Edit Post</li>
          <li onClick={() => openModal(post)}>Delete Post</li>
        </ul>
      );
    }
  };

  const commentBoxJSX = (comment, post, ind) => (
    <li className="comment-parent" key={ind}>
      <ul>
        <li className="comment-bar">
          <figure>
            {comment?.userDetails?.[0]?.profileImage ? (
              <img
                src={
                  mediaURL +
                  handlecommentimage(comment?.userDetails?.[0]?.title) +
                  comment?.userDetails?.[0]?.profileImage
                }
                alt="leader"
              />
            ) : (
              <img
                src={toAbsoluteUrl("/images/placeholder.png")}
                alt="placeholder"
              />
            )}
          </figure>
          <div className="comment-wrap">
            <h3 className="commentPersonName">
              {comment?.userDetails[0]?.title === "Donor"
                ? comment?.userDetails[0]?.firstName +
                  " " +
                  comment?.userDetails?.[0]?.lastName
                : comment?.userDetails?.[0]?.firstName}
            </h3>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              <p className="commentShow">{comment?.comment}</p>
            </Linkify>
            {/* <p className="commentShow">{comment?.comment}</p> */}
          </div>
        </li>
        <ul className="postCommentAction">
          <li
            className={!comment?.islike ? "postLike" : "postLikeActive"}
            onClick={() => likeUnlikeCommentHandler(comment?._id, post?._id)}
          >
            <Heart />
            <span className="comment-like">{comment?.likeCount} Like</span>
          </li>
          <li onClick={() => showCommentReplyBoxHandler(comment._id)}>
            <span>{`${comment?.replyCommnet?.length} ${
              comment?.replyCommnet?.length > 1 ? "Replies" : "Reply"
            }`}</span>
          </li>
        </ul>
      </ul>
      {isReplyCommentBoxOpen === comment?._id && (
        <>
          <div className="replyBox">
            <figure>
              {profilePic !== "null" ? (
                <img
                  src={mediaURL + "userProfilePic/" + profilePic}
                  alt="leader"
                />
              ) : (
                <img
                  src={toAbsoluteUrl("/images/placeholder.png")}
                  alt="placeholder"
                />
              )}
              {/* <img src={mediaURL + "userProfilePic/" + profilePic} alt="user" /> */}
            </figure>
            <div className="form-group">
              <textarea
                value={replyText}
                placeholder="Write your comment"
                onChange={(e) => setReplyText(e.target.value)}
              />
              <button
                className="commentReplyButton"
                onClick={() =>
                  postCommentHandler(post, true, comment?._id, true)
                }
                disabled={!replyText || !replyText.trim()}
              >
                Reply
              </button>
            </div>
          </div>
          <ul className="comment-child">
            {comment?.replyCommnet?.map((replyComment, ind) => (
              <li className="comment-child-list" key={replyComment?._id}>
                <div className="comment-bar comment-tree-bar">
                  <figure>
                    {replyComment?.userDetails?.[0]?.profileImage ? (
                      <img
                        src={
                          mediaURL +
                          handlecommentimage(
                            replyComment?.userDetails?.[0]?.title
                          ) +
                          replyComment?.userDetails?.[0]?.profileImage
                        }
                        alt="leader"
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl("/images/placeholder.png")}
                        alt="placeholder"
                      />
                    )}
                    {/* <img
                      src={toAbsoluteUrl("/images/leader-one.png")}
                      alt="leader"
                    /> */}
                  </figure>
                  <div className="comment-wrap">
                    <h3 className="commentPersonName">
                      {replyComment?.userDetails[0]?.title === "Donor"
                        ? replyComment?.userDetails[0]?.firstName +
                          " " +
                          replyComment?.userDetails?.[0]?.lastName
                        : replyComment?.userDetails?.[0]?.firstName}
                    </h3>
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      <p className="comment-text">{replyComment?.comment}</p>
                    </Linkify>
                  </div>
                </div>
                <ul className="postCommentAction">
                  <li
                    className={
                      !replyComment?.islike ? "postLike" : "postLikeActive"
                    }
                    onClick={() =>
                      likeUnlikeCommentHandler(replyComment?._id, post?._id)
                    }
                  >
                    <Heart />
                    <span className="comment-like">
                      {replyComment?.likeCount} Like
                    </span>
                  </li>
                  <li
                    onClick={() =>
                      showCommentReplyReplyBoxHandler(replyComment?._id)
                    }
                  >
                    <span>Reply</span>
                  </li>
                </ul>
                {isReplyReplyCommentBoxOpen === replyComment?._id && (
                  <div className="replyBox childReplyBox">
                    <figure>
                      {profilePic !== "null" ? (
                        <img
                          src={mediaURL + "userProfilePic/" + profilePic}
                          alt="leader"
                        />
                      ) : (
                        <img
                          src={toAbsoluteUrl("/images/placeholder.png")}
                          alt="placeholder"
                        />
                      )}
                    </figure>
                    <div className="form-group">
                      <textarea
                        value={replyReplyText}
                        placeholder="Write your comment"
                        onChange={(e) => setReplyReplyText(e.target.value)}
                      />
                      <button
                        className="commentReplyButton"
                        onClick={() =>
                          postCommentHandler(post, true, comment?._id)
                        }
                        disabled={!replyReplyText || !replyReplyText.trim()}
                      >
                        Reply
                      </button>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </li>
  );

  const closeAddPostModalHandler = () => {
    setEditIsOpen((prev) => !prev);
    setDeleteImage([]);
    setDeletedVideoArray([]);
  };

  const userProfileClickHandler = () => {
    if (post?.userDetails) {
      navigate(`/user-profile/${post?.userDetails?._id}`);
    }
  };

  const onMessageBtnClick = (e) => {
    e.stopPropagation();
    navigate("/chat", {
      state: {
        from: "post",
        sendToId: `${
          post?.strategicPartnerDetails?._id
            ? post?.strategicPartnerDetails?._id
            : post?.userId
        }`,
        type: `${post?.strategicPartnerDetails?._id ? "stategic" : "user"}`,
        name: `${
          post?.strategicPartnerDetails?.firstName
            ? post?.strategicPartnerDetails?.firstName
            : post?.userDetails?.firstName + post?.userDetails?.lastName
        }`,
      },
    });
  };

  const likeCountHandler = () => {
    if (isLike && !post?.islike) {
      return post?.countLike + 1;
    } else if (!isLike && post?.islike) {
      return post?.countLike - 1;
    } else {
      return post?.countLike;
    }
  };

  const renderCommunityName = () => {
    if (post?.userDetails) {
      return post?.userDetails?.communityName;
    } else if (post?.causeDetails) {
      return post?.causeDetails?.communityName;
    } else {
      return post?.charityDetails?.communityName;
    }
  };

  return (
    <>
      <PostCard>
        <div className="postHeader">
          <div className="postWrap" onClick={() => userProfileClickHandler()}>
            <figure>{getPostProfile()}</figure>
            <div className="postMember">
              <div className="postMemberName">
                <h3>{getPostUserName()}</h3>
              </div>
              <div className="postTime">
                {from !== "postModule" && (
                  <span>{post?.userDetails ? "Member" : "Administrator"}</span>
                )}
                {isMyPost === undefined && (
                  <div>
                    <span className="communityName">
                      {post?.userDetails?.communityName}
                      {renderCommunityName()}
                    </span>
                  </div>
                )}
                <span>{moment(post?.createdDate).fromNow()}</span>
              </div>
            </div>
          </div>
          {userId && user?.data?.userType !== 3 && showAction !== false && (
            <div className="postMenu" ref={modalRef}>
              <div className="dropdownAvtar">
                <figure onClick={() => dropdownOpen()}>
                  <img
                    src={toAbsoluteUrl("/images/profile.svg")}
                    alt="profile"
                  />
                </figure>
                {isMoreOpen === true && postActions()}
              </div>
            </div>
          )}
        </div>
        <div className="postDesc">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            <p className={!readDesc ? "" : "readLess"}>{post?.description}</p>
          </Linkify>
          {post?.description?.length ? (
            <button
              className={post?.description?.length < 200 ? "hideBtn" : ""}
              onClick={() => setReadDesc((prev) => !prev)}
            >
              {readDesc ? "Read More" : "Read Less"}
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="mainPostContentWrap">
          {post?.type !== 3 && (
            <div className="postImage">
              {/* <figure>
                      <img
                        src={post?.image?.[0]?.url}
                        alt="postImage"
                      ></img>
                    </figure> */}
              <Swiper
                pagination={pagination}
                className="post-swiper"
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={40}
                navigation={true}
              >
                {post?.image?.length > 0 &&
                  post?.image?.map((image) => (
                    <SwiperSlide key={image?._id}>
                      <figure>
                        <img src={image?.url} alt="postImage" />
                      </figure>
                    </SwiperSlide>
                  ))}
                {post?.image?.length === 0 &&
                  post?.video?.length > 0 &&
                  post?.video?.map((video, ind) => (
                    <Player
                      className="post-video"
                      key={video?._id}
                      poster={
                        mediaURL +
                        "postImages/videos/thumbnail/" +
                        video?.thumbnail
                      }
                    >
                      <source src={video?.url} />
                    </Player>
                  ))}
              </Swiper>
            </div>
          )}
          <div className="postAction">
            <ul>
              <li
                className={!isLike ? "postLike" : "postLikeActive"}
                onClick={() => likeBtnClickHandler(post?._id, post?.islike)}
              >
                <Heart />
                <span className="postActionCount">{likeCountHandler()}</span>
              </li>
              <li onClick={() => showCommentHandler(post._id)}>
                <Comment />
                <span className="postActionCount">
                  {communityCommentList?.total_records?.[0]?.count
                    ? communityCommentList?.total_records?.[0]?.count
                    : post?.countComment}
                </span>
              </li>
              <li onClick={() => sharePostHandler(post._id)}>
                <Share />
                <span className="postActionCount">{post?.countShare}</span>
              </li>
            </ul>
          </div>
        </div>
        {isCommentOpen === post?._id && (
          <ul className="comment-sectionList">
            <div className="replyBox replyBoxHeader">
              <figure>
                {profilePic !== "null" ? (
                  <img
                    src={mediaURL + "userProfilePic/" + profilePic}
                    alt="user"
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl("/images/placeholder.png")}
                    alt="placeholder"
                  />
                )}
              </figure>
              <div className="form-group">
                <textarea
                  value={commentText}
                  placeholder="Write your comment"
                  onChange={(e) => setCommentText(e.target.value)}
                />
                <button
                  className="commentReplyButton"
                  onClick={() => postCommentHandler(post, false)}
                  disabled={!commentText || !commentText.trim()}
                >
                  Comment
                </button>
              </div>
            </div>
            {communityCommentList?.comment?.map((comment, ind) =>
              commentBoxJSX(comment, post, ind)
            )}
            {communityCommentList?.total_records?.[0]?.count > 3 && (
              <span
                className="commentToggle"
                onClick={() => {
                  renderCommentsHandler(true);
                }}
              >
                {showMoreComment !== 1 ? "View More" : "View Less"}
              </span>
            )}
          </ul>
        )}
      </PostCard>
      <CustomModal
        className="postDeleteModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
      >
        <h3>Are you sure you want to delete this post?</h3>
        <p>
          Are you sure you want to delete this post, after this action this post
          will permanentely deleted from server.
        </p>
        <div className="postModal">
          <button onClick={() => setModalIsOpen((prev) => !prev)}>
            Cancel
          </button>
          <button onClick={deletePostHandler}>Delete</button>
        </div>
      </CustomModal>
      <CustomModal
        className="postEditModal"
        isOpen={editIsOpen}
        closeModal={() => closeAddPostModalHandler()}
        title="Edit Post"
      >
        <Form
          onSubmit={formSubmitHandler}
          initialValues={{
            postContent: currPostDetails?.description,
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <Field
                  name="postContent"
                  component={rendertextarea}
                  className="textarea"
                  placeholder="Type here"
                  validate={required}
                ></Field>
                {postType === "photo" && (
                  <ImgUpload
                    isPost={true}
                    multiple={true}
                    setImg={setImage}
                    imgs={image}
                    setImages={setPostImages}
                    images={postImages}
                    oldImages={oldPostImages}
                    setDeleteImage={(image) =>
                      setDeleteImage([...deleteImage, image])
                    }
                    setOldImages={(images) => setOldPostImages(images)}
                    maxNumber={5 - oldPostImages?.length}
                    moduleName="postImages"
                    postPage={true}
                  />
                )}
                {postType === "video" && (
                  <RenderVideoField
                    oneVideoValidation={true}
                    isMultiple={false}
                    moduleName="postImages"
                    videoArray={videoArray}
                    setVideoArray={setVideoArray}
                    videoSourceURLs={videoSourceURLs}
                    setVideoSourceURLs={setVideoSourceURLs}
                    videoSourceFiles={videoSourceFiles}
                    setVideoSourceFiles={setVideoSourceFiles}
                    setDeletedVideoArray={(image) =>
                      setDeletedVideoArray([...deletedVideoArray, image])
                    }
                    containOldImages={true}
                  />
                )}
                {!isMyPost && !from && (
                  <ul>
                    <li>
                      <div className="forgot">
                        <div className="remember">
                          <input
                            className="check"
                            type="checkbox"
                            id="public"
                            value="public"
                            name="public"
                            checked={active === "public"}
                            onChange={radioButtonHandler}
                          />
                          <label htmlFor="public">Public Post</label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="forgot">
                        <div className="remember">
                          <input
                            className="check"
                            type="checkbox"
                            id="private"
                            value="private"
                            name="private"
                            checked={active === "private"}
                            onChange={radioButtonHandler}
                          />
                          <label htmlFor="private">Private Post</label>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
                <div className="editButton">
                  <button
                    type="button"
                    onClick={() => closeAddPostModalHandler()}
                  >
                    Cancel
                  </button>
                  <button type="submit">Edit Post</button>
                </div>
              </form>
            </>
          )}
        </Form>
      </CustomModal>
    </>
  );
};

export default Post;

export const LinkifyLink = ({ decoratedHref, key, decoratedText }) => {
  return (
    <a target="blank" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
};
