import React from "react";
import CustomStar from "./StarRating";
import "./rating.scss";
import RatingMedals from "../../../../components/common/RatingMedals";
import { toAbsoluteUrl } from "../../../../utils";

function Rating({
  ratingType,
  emcompassDetais,
  starDetails,
  encompassAvg,
  starRatingAvg,
  transperancyBenchmark,
  governanceBenchmark,
  gradeReceive,
  guideStar,
  isNavigator,
}) {
  const RatingValue = (value) => {
    if (value >= 90) {
      return 4;
    } else if (value < 90 && value >= 75) {
      return 3;
    } else if (value < 75 && value >= 60) {
      return 2;
    } else if (value < 60 && value >= 50) {
      return 1;
    } else {
      return 0;
    }
  };
  return (
    <>
      <div className="rating">
        {/* star rating */}
        {ratingType === "Star" ? (
          <div className="nonprofit-navigator">
            <a href="true" onClick={(e) => e.preventDefault()}>
              <img
                src={toAbsoluteUrl("/images/charity-navigator.png")}
                alt="charity navigator"
              />
            </a>
          </div>
        ) : null}
        {ratingType === "Star" && (
          <div className="starRating">
            <div className="star-heading">
              <h3>Encompass Rating :</h3>
            </div>

            <div className="totalStar">
              <div className="totalStar-main">
                <label>Overall Star Rating :</label>
                {starDetails?.ratingAvg ? (
                  <CustomStar
                    rating={RatingValue(starDetails?.ratingAvg)}
                    starDimension="35px"
                    starSpacing="6px"
                  />
                ) : (
                  "Not Evaluated"
                )}
              </div>
              <div className="total-score">
                <label>Overall Score :</label>
                {starDetails?.ratingAvg ? (
                  <span>{starDetails?.ratingAvg}%</span>
                ) : (
                  "Not Evaluated"
                )}
              </div>
            </div>

            <div className="starCount">
              {!isNavigator && starDetails?.impactResults ? (
                <div className="leftStar">
                  <h4 className="rating-label">Impact & Results</h4>
                  {starDetails?.impactResults ? (
                    <CustomStar
                      rating={RatingValue(starDetails?.impactResults)}
                      starDimension="35px"
                      starSpacing="6px"
                    />
                  ) : (
                    "Not Evaluated"
                  )}
                  {starDetails?.impactResults ? (
                    <span>{starDetails?.impactResults} %</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}

              {!isNavigator && starDetails?.accountabilityFinance ? (
                <div className="rightStar">
                  <h4 className="rating-label">Accountability & Finance</h4>
                  {starDetails?.accountabilityFinance ? (
                    <CustomStar
                      rating={RatingValue(starDetails?.accountabilityFinance)}
                      starDimension="35px"
                      starSpacing="6px"
                    />
                  ) : (
                    "Not Evaluated"
                  )}
                  {starDetails?.accountabilityFinance ? (
                    <span>{starDetails?.accountabilityFinance} %</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
            <div className="starCount">
              {!isNavigator && starDetails?.cultureCommunity ? (
                <div className="leftStar">
                  <h4 className="rating-label">Culture & Community</h4>
                  {starDetails?.cultureCommunity ? (
                    <CustomStar
                      rating={RatingValue(starDetails?.cultureCommunity)}
                      starDimension="35px"
                      starSpacing="6px"
                    />
                  ) : (
                    "Not Evaluated"
                  )}
                  {starDetails?.cultureCommunity ? (
                    <span>{starDetails?.cultureCommunity} %</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}

              {!isNavigator && starDetails?.cultureCommunity ? (
                <div className="rightStar">
                  <h4 className="rating-label">Leadership & Adaptability</h4>
                  {starDetails?.leadershipAdaptability ? (
                    <CustomStar
                      rating={RatingValue(starDetails?.leadershipAdaptability)}
                      starDimension="35px"
                      starSpacing="6px"
                    />
                  ) : (
                    "Not Evaluated"
                  )}
                  {starDetails?.leadershipAdaptability ? (
                    <h4>{starDetails?.leadershipAdaptability} %</h4>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )}

        <div className="medals">
          <RatingMedals
            transperancyBenchmark={transperancyBenchmark}
            governanceBenchmark={governanceBenchmark}
            gradeReceive={gradeReceive}
            guideStar={guideStar}
          />
        </div>
      </div>
    </>
  );
}

export default Rating;
