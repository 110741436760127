import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { getVideoUrl, toAbsoluteUrl } from "../../../utils";
import Delete from "../../svg/delete";
import EyeLock from "../../../assets/svg/eyeLock";

export const RenderVideoField = ({
  videoArray,
  setVideoArray,
  videoSourceURLs,
  setVideoSourceURLs,
  videoSourceFiles,
  setVideoSourceFiles,
  setDeletedVideoArray,
  oldVideos = [],
  moduleName,
  isMultiple,
  oneVideoValidation = false,
  containOldImages,
}) => {
  const { mediaURL } = useSelector((state) => state.metaReducer);
  let ref = useRef();

  const handleFileChange = (event) => {
    let fileArray = Object.values(event.target.files) || [];
    if (fileArray.length) {
      setVideoSourceFiles(fileArray);
      fileArray.forEach((_, ind) => {
        const file = event.target.files[ind];
        const url = URL.createObjectURL(file);
        setVideoSourceURLs([...videoSourceURLs, url]);
        return null;
      });
    }
  };
  const onDelete = (val) => {
    // dispatch(doVideoDelete(data)).then(() => {
    let filteredVideos = videoArray.filter((o) => o?.name !== val);
    setVideoArray(filteredVideos);
    setDeletedVideoArray({ name: val });
    // });
  };

  const deleteLocalImageFile = (index) => {
    let filteredVideos = videoSourceFiles.filter((_, i) => i !== index);
    let filteredVideosURL = videoSourceURLs.filter((_, i) => i !== index);
    setVideoSourceFiles(filteredVideos);
    setVideoSourceURLs(filteredVideosURL);
  };
  const onViewvideo = (val) => {
    window.open(val);
  };

  return (
    <>
      <div className="postVideoUpload upload__image-wrapper">
        <div className="display-video img-map-box">
          {containOldImages && videoArray?.length
            ? videoArray.map((val, i) => {
                return (
                  <>
                    <div className="video-box">
                      <div
                        key={val?._id}
                        className="video-box image-item video-post-box"
                      >
                        <a
                          href={`${val?.url}`}
                          target={"_blank"}
                          rel="noreferrer"
                          className="videoPostThumbnail"
                        >
                          <img
                            src={getVideoUrl(
                              mediaURL,
                              moduleName,
                              val?.thumbnail,
                              true
                            )}
                            alt={val?.name}
                            height={80}
                            width={80}
                          />
                        </a>
                        <div className="video-item__btn-wrapper">
                          <button
                            type="button"
                            className="delete img-btn video-dlt"
                            onClick={() => {
                              onDelete(val?.name);
                            }}
                          >
                            <Delete />
                          </button>
                          <button
                            type="button"
                            className="video-eye"
                            onClick={() => {
                              onViewvideo(val?.url);
                            }}
                          >
                            <EyeLock />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
          {videoSourceURLs?.length
            ? videoSourceURLs.map((url, ind) => {
                return (
                  <>
                    <div className="video-box image-item video-post-box">
                      <a
                        href={url}
                        target={"_blank"}
                        rel="noreferrer"
                        className="videoPostThumbnail"
                      >
                        <video
                          key={url}
                          src={url}
                          // control
                          style={{
                            width: "90px",
                            height: "90px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <div className="video-item__btn-wrapper">
                        <button
                          type="button"
                          className="delete img-btn video-dlt"
                          onClick={() => deleteLocalImageFile(ind)}
                        >
                          <Delete />
                        </button>
                        <button
                          type="button"
                          className="video-eye"
                          onClick={() => {
                            onViewvideo(url);
                          }}
                        >
                          <EyeLock />
                        </button>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </div>
        <div className="upload-input">
          {oneVideoValidation ? (
            videoArray?.length + videoSourceURLs?.length < 1 && (
              <div className="editUpload" onClick={() => ref.current.click()}>
                <div className="uploading">
                  <figure>
                    <img
                      src={toAbsoluteUrl("/images/uploading.svg")}
                      alt="uploading"
                    />
                  </figure>
                  <div className="fileContent">
                    <h4>Select a video or drag and drop here</h4>
                    <p>Recommanded video should be less than 2 minutes</p>
                  </div>

                  <button type="button">Select File</button>
                </div>
              </div>
            )
          ) : (
            <button
              type="button"
              className="btn btn-light upload-btn"
              onClick={() => {
                ref.current.click();
              }}
            >
              +
            </button>
          )}
        </div>
      </div>
      <input
        style={{ display: "none" }}
        ref={ref}
        multiple={isMultiple}
        // onChange={(e) => onVideoSelect(e)}
        onChange={(e) => handleFileChange(e)}
        type={"file"}
        accept="video/*"
      />
    </>
  );
};
