import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../utils";
import "./card_signin.scss";

function Card_signin(props) {
  return (
    <>
      <div className="card-body">
        <div className="logo">
          <Link to="">
            <img src={toAbsoluteUrl("/images/logo.svg")} alt="logo" />
          </Link>
        </div>
        <h3>{props.title}</h3>
        {props.children}
        <div className="footer">
          <p>© {new Date().getUTCFullYear()} Rosi Giving, Inc.</p>
        </div>
      </div>
    </>
  );
}

export default Card_signin;
