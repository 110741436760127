/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToBasketAction } from "../../../../actions/cart";
import { renderCauseCommunity } from "../../../../actions/cause";
import { renderCharityCommunity } from "../../../../actions/charity";
import {
  joinCommunityHandler,
  leaveCommunityHandler,
} from "../../../../actions/community";
import { BASKET_COUNT_S } from "../../../../constants/types";
import { toAbsoluteUrl } from "../../../../utils";
import "./tabCommunity.scss";

function TabCommunity({ communityList, from, causeId, charityId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;

  const user = JSON.parse(localStorage.getItem("webUser"));

  useEffect(() => {
    dispatch(renderCauseCommunity({ _id: causeId }));
  }, [causeId]);

  const joinCommunityBtnHandler = (e, community) => {
    e.stopPropagation();
    if (!token) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (from === "cause") {
      if (community?.isJoined) {
        dispatch(leaveCommunityHandler(community?.joinCommId)).then(() =>
          dispatch(renderCauseCommunity({ _id: causeId }))
        );
      } else {
        dispatch(
          joinCommunityHandler({
            charityId: community?.charityId ? community?.charityId : null,
            causeId: community?.causeId ? community?.causeId : null,
            communityId: community?._id,
            userId: user?.data?._id,
          })
        ).then(() => dispatch(renderCauseCommunity({ _id: causeId })));
      }

      return;
    }
    if (community?.isJoined) {
      dispatch(leaveCommunityHandler(community?.joinCommId)).then(() =>
        dispatch(renderCharityCommunity({ charityId: community?.charityId }))
      );
    } else {
      dispatch(
        joinCommunityHandler({
          charityId: community?.charityId ? community?.charityId : null,
          causeId: community?.causeId ? community?.causeId : null,
          communityId: community?._id,
          userId: user?.data?._id,
        })
      ).then(() =>
        dispatch(renderCharityCommunity({ charityId: community?.charityId }))
      );
    }
  };

  const handleBasketButtonClick = (e, community) => {
    e.stopPropagation();
    if (!token && user?.data?.userType !== 3) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (community?.isBasket) {
      navigate("/basket");
      return;
    }
    const payload = {
      userId,
      basket: [
        {
          communityId: community?._id,
          type: "community",
        },
      ],
    };

    dispatch(addToBasketAction(payload))
      .then(() => {
        dispatch({
          type: BASKET_COUNT_S,
          payload: basketCount + 1,
        });
        toast.success("Charity added to basket successfully.");
        if (charityId) {
          dispatch(renderCharityCommunity({ charityId: charityId }));
        } else {
          dispatch(renderCauseCommunity({ _id: causeId }));
        }
      })
      .catch(() => toast.error("Something went wrong"));
  };
  return (
    <>
      <div className="tabCommunity">
        <div className="tabCommunityHeader">
          <h3>Join Community</h3>
          <button onClick={() => navigate("/community")}>View All</button>
        </div>

        <div className="tabCommunityBodyWrap">
          <ScrollBar component="div">
            <div className="content">
              {communityList?.length > 0 ? (
                communityList?.map((community) => (
                  <div
                    className="tabCommunityBody" key={community?._id}
                    onClick={() =>
                      navigate(`/community_details/${community?._id}`, {
                        state: {
                          isAssociated: community?.isJoined ? false : true,
                        },
                      })
                    }
                  >
                    <div className="tabCommunityLeft">
                      <figure className="imageBorder">
                        {community?.logo ? (
                          <img
                            src={`${mediaURL}communityLogo/${community?.logo}`}
                            alt="logo"
                          />
                        ) : (
                          <img
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt="placeholder"
                          />
                        )}
                      </figure>
                      <h3>{community?.communityName}</h3>
                    </div>
                    <div className="tabCommunityRight">
                      <button
                        className="communityBasket"
                        onClick={(e) => handleBasketButtonClick(e, community)}
                      >
                        {!community?.isBasket
                          ? "Add To Basket"
                          : "Go To Basket"}
                      </button>
                      <button
                        className={community?.isJoined ? "leave" : "join"}
                        onClick={(e) => joinCommunityBtnHandler(e, community)}
                      >
                        {community?.isJoined ? "Leave" : "Join"}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h4 className="notFound">No Community Found</h4>
              )}
            </div>
          </ScrollBar>
        </div>
      </div>
    </>
  );
}

export default TabCommunity;
