/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToBasketAction } from "../../../actions/cart";
import { renderCauseDetails } from "../../../actions/cause";
import { addCharityToFav, removeFav } from "../../../actions/favourite";
import Heart from "../../../assets/svg/heart";
import { BASKET_COUNT_S } from "../../../constants/types";
import { toAbsoluteUrl } from "../../../utils";

const RelatedCharityCard = ({ charity, causeId }) => {
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const user = JSON.parse(localStorage.getItem("webUser"))?.data;
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFav, setIsFav] = useState();

  useEffect(() => {
    if (charity?.isFavorite === true) {
      setIsFav(true);
    } else {
      setIsFav(false);
    }
  }, []);

  const handleFavClick = (e) => {
    e.stopPropagation();
    if (!token || user.userType === 3) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (!isFav) {
      const payload = { userId, charityId: charity?.charityId };
      dispatch(addCharityToFav(payload)).then(() =>
        dispatch(renderCauseDetails({ _id: causeId }))
      );
    } else {
      const payload = { _id: userId, charityId: charity?.charityId };
      dispatch(removeFav(payload)).then(() =>
        dispatch(renderCauseDetails({ _id: causeId }))
      );
    }

    setIsFav((prev) => !prev);
  };
  const handleBasketButtonClick = (e, charity) => {
    e.stopPropagation();
    if (!userId) {
      toast.error("Please login to add this charity in the basket");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (charity?.isBasket) {
      navigate("/basket");
      return;
    }
    const payload = {
      userId,
      basket: [
        {
          charityId: charity?.charityId,
          type: "charity",
        },
      ],
    };

    dispatch(addToBasketAction(payload))
      .then(() => {
        toast.success("Charity added to basket successfully.");
        dispatch(renderCauseDetails({ _id: causeId })).then(() =>
          dispatch({
            type: BASKET_COUNT_S,
            payload: basketCount + 1,
          })
        );
      })
      .catch(() => toast.error("Something went wrong"));
  };
  return (
    <div
      className="relatedBody"
      onClick={() => navigate(`/charity_details/${charity?.charityId}`)}
    >
      <div className="relatedLeft">
        <figure className="imageBorder">
          {charity?.charityLogo ? (
            <img
              src={`${mediaURL}charityLogo/${charity?.charityLogo}`}
              alt="logo"
            />
          ) : (
            <img
              src={toAbsoluteUrl("/images/placeholder.png")}
              alt="placeholder"
            />
          )}
        </figure>
        <h3>{charity.charityName}</h3>
      </div>
      <div className="relatedRight">
        <div className="relatedButtons">
          <button
            className="basket"
            onClick={(e) => handleBasketButtonClick(e, charity)}
          >
            {!charity?.isBasket ? "Add To Basket" : "Go To Basket"}
          </button>
          {/* <Link className="basket" to="">
            Add To Basket
          </Link> */}
          <button
            // to=""
            className={
              charity?.isFavorite === true ? "causeHeartActive" : "causeHeart"
            }
            onClick={handleFavClick}
          >
            <Heart />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RelatedCharityCard;
