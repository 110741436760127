import React from "react";
import { useNavigate } from "react-router-dom";
import Down from "./../../../../assets/svg/down";
import "./tabCause.scss";
import { toAbsoluteUrl } from "../../../../utils/index";
import ScrollBar from "react-perfect-scrollbar";

function TabCause({ causeList }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="tabCause">
        <div className="tabCauseHeader">
          <h3>Causes</h3>
          <button onClick={() => navigate(`/cause`)}>View All</button>
        </div>
        <div className="tabCauseBodyWrap">
          <ScrollBar component="div">
            <div className="content">
              {causeList?.length > 0 ? (
                causeList?.map((cause, ind) => (
                  <div
                    className="tabCauseBody"
                    key={cause._id}
                    onClick={() => navigate(`/cause_details/${cause._id}`)}
                  >
                    <div className="tabCauseLeft">
                      <figure className="imageBorder">
                        {cause?.images?.url &&
                        cause?.images?.url !== "string" ? (
                          <img src={cause?.images?.url} alt="logo" />
                        ) : (
                          <img
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt="placeholder"
                          />
                        )}
                      </figure>
                      <h3>{cause?.causename}</h3>
                    </div>
                    <div className="tabCauseRight">
                      <button>
                        <Down />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h4 className="notFound">No cause list found</h4>
              )}
            </div>
          </ScrollBar>
        </div>
      </div>
    </>
  );
}

export default TabCause;
