import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { renderCauseDetails } from "../../actions/cause";
import { addCauseToFav, removeFav } from "../../actions/favourite";
import Heart from "../../assets/svg/heart";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Share from "../../components/common/Share";
import CustomTab from "../../components/common/Tabs";
import { toAbsoluteUrl } from "../../utils";
import TabCommunity from "../Charity Details/TabsContent/TabCommunity";
import Events from "../Community Details/CommunityTabs/Events";
import "./causeDetails.scss";
import RelatedCharity from "./TabsCauseContent/RelatedCharity";

const bread = [
  {
    name: "Cause List",
    link: "/cause",
    isActive: false,
  },
  {
    name: "Cause Details",
    link: "/cause_details",
    isActive: true,
  },
];

function CauseDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { causeDetails, causeCommunityList } = useSelector(
    (state) => state.cause
  );
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const { id } = useParams();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;

  const [readDesc, setReadDesc] = useState(true);
  const [isFav, setIsFav] = useState();

  useEffect(() => {
    setIsFav(causeDetails?.causeDetails?.isFavorite);
  }, [causeDetails?.causeDetails?.isFavorite]);

  useEffect(() => {
    dispatch(renderCauseDetails({ _id: id }));
    // eslint-disable-next-line
  }, [id]);

  const causeTabs = [
    {
      heading: "Events",
      body: causeDetails?.causeDetails?._id && (
        <Events id={causeDetails?.causeDetails?._id} from="cause" />
      ),
    },
    {
      heading: "Related Nonprofits",
      body: (
        <RelatedCharity
          charityList={causeDetails?.relatedCharityObj?.relatedCharity}
          causeId={id}
        />
      ),
    },
    {
      heading: "Join Community",
      body: (
        <TabCommunity
          communityList={causeCommunityList}
          causeId={id}
          from="cause"
        />
      ),
    },
  ];

  const handleFavClick = (e) => {
    e.stopPropagation();
    if (!token) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (!isFav) {
      const payload = { userId, causeId: causeDetails?.causeDetails?._id };
      dispatch(addCauseToFav(payload)).then(() => {
        setIsFav(true);
      });
    } else {
      const payload = { _id: userId, causeId: causeDetails?.causeDetails?._id };
      dispatch(removeFav(payload)).then(() => {
        setIsFav(false);
      });
    }
  };

  return (
    <section className="causeDeails">
      <div className="container">
        <div className="breadcrumbscause">
          <Breadcrumbs list={bread} />
        </div>
      </div>
      <div className="container">
        <div className="causeDetailsWrap">
          <div className="causeSidebar">
            {/* detail-card */}
            <div className="detailCard">
              <figure className="detailsFigure">
                {causeDetails?.causeDetails?.images?.url ? (
                  <img
                    src={causeDetails?.causeDetails?.images?.url}
                    alt="detailsImage"
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl("/images/placeholder.png")}
                    alt="placeholder"
                  />
                )}
              </figure>
              <div className="causeDetailsContent">
                <div className="causeDetailsContentWrap">
                  <div className="cardUser">
                    <h3>{causeDetails?.causeDetails?.causename}</h3>
                  </div>
                  <div className="member">
                    <div className="member-list">
                      <div className="member-images">
                        {causeDetails?.causeDetails?.causeFavMemberImg?.map(
                          (image, ind) => (
                            <figure key={image?.image}>
                              {image ? (
                                <img
                                  src={
                                    mediaURL + "userProfilePic/" + image.image
                                  }
                                  alt="user"
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl("/images/placeholder.png")}
                                  alt="placeholder"
                                />
                              )}
                            </figure>
                          )
                        )}
                      </div>
                      <span className="member-count">
                        {causeDetails?.causeDetails?.causeFavMemberCount}{" "}
                        Favorites
                      </span>
                    </div>
                  </div>
                </div>
                <div className="cardButton">
                  <button
                    className={`${isFav ? "causeHeartActive" : "causeHeart"}`}
                    onClick={handleFavClick}
                  >
                    <Heart />
                  </button>
                </div>
              </div>
            </div>
            {/* research */}
            <div className="research">
              <h3>{causeDetails?.causeDetails?.causename}</h3>
              <p className={!readDesc ? "" : "readLess"}>
                {causeDetails?.causeDetails?.description}
              </p>
              <div className="read-btn">
                <button
                  className={
                    causeDetails?.causeDetails?.description?.length < 155
                      ? "hideBtn"
                      : ""
                  }
                  onClick={() => setReadDesc((prev) => !prev)}
                >
                  {readDesc ? "Read More" : "Read Less"}
                </button>
              </div>
            </div>
            {/* share */}
            <Share />
          </div>
          <div className="causeContent">
            <CustomTab className="causeTab" data={causeTabs} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CauseDetail;
