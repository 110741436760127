import React from "react";
import { toAbsoluteUrl } from "../../../utils";
import "./eventCard.scss";
import Location from "../../../assets/svg/location";
import BirthDay from "../../../assets/svg/birthday";
import Time from "../../../assets/svg/time";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function EventCard(props) {
  const { event, classname, from, communityId, comingFrom, isEvent } = props;
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const navigate = useNavigate();

  const cardClickHandler = () => {
    navigate(`/event_details/${event?._id}`, {
      state: { from, communityId, comingFrom },
    });
  };

  const buttonTextHandler = () => {
    if (isEvent === false) {
      return "View Event";
    } else if (isEvent === "myEvent") {
      return "Going";
    } else if (event?.userEventStatus) {
      if (event?.userEventStatus === "Notgoing") return "Not Going";
      return event?.userEventStatus;
    }
    return "Join Event";
  };

  return (
    <>
      <div className="eventCard" onClick={cardClickHandler}>
        <figure className="eventFigure">
          {event?.eventImage ? (
            <img
              src={mediaURL + "eventImages/" + event?.eventImage}
              alt="eventImage"
            />
          ) : (
            <img
              src={toAbsoluteUrl("/images/placeholder.png")}
              alt="placeholder"
            />
          )}
        </figure>
        <div className="eventDescription">
          <h3>{event?.eventName}</h3>
          <ul className="eventTime">
            {event.communityName && (
              <li>
                <img
                  src={toAbsoluteUrl("/images/community-leave.png")}
                  alt="community-logo"
                />
                <span className="communityTexts">{event.communityName}</span>
              </li>
            )}
            {event?.charityName && (
              <li>
                <img
                  src={toAbsoluteUrl("/images/charity_home.png")}
                  alt="community-logo"
                />
                <span className="charityTexts">{event.charityName}</span>
              </li>
            )}
            <li>
              <BirthDay />
              <span>{moment(event?.startDate).format("MM-DD-YYYY")}</span>
            </li>
            <li>
              <Time />
              <span>{moment(event?.eventTime).format("hh:mm A")}</span> -
              <span>{moment(event?.eventEndTime).format("hh:mm A")}</span>
            </li>
            <li>
              <div className="address">
                <Location />
                {event?.eventType !== "virtual" ? (
                  <address>
                    {event?.address?.city +
                      ", " +
                      event?.address?.state +
                      ", " +
                      event?.address?.zipcode}
                  </address>
                ) : (
                  <address>Virtual Event</address>
                )}
              </div>
            </li>
          </ul>
        </div>
        <div className={classname}>
          <button>{buttonTextHandler()}</button>
          {/* <button>{`${props?.isEvent ? "Join Event" : `View Event`}`}</button> */}
        </div>
      </div>
    </>
  );
}

export default EventCard;
