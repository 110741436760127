/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./postTab.scss";
import Search from "../../../assets/svg/search";
import ScrollBar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../utils";
import Cancle from "../../../assets/svg/cancle";
import Telegram from "../../../assets/svg/telegram";
import {
  acceptRejectFriendRequestAction,
  pendingFriendRequestAction,
  renderAllUserListAction,
  renderFriendListAction,
  sendFriendRequestAction,
  unfriendFriendAction,
} from "../../../actions/friend";
import { useNavigate } from "react-router-dom";
import { getUserDetailsAction } from "../../../actions/post";

function PostTab(props) {
  const { postHeaderName, from, setUserDetails } = props;
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const dispatch = useDispatch();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const navigate = useNavigate();

  const [pendingFriendList, setPendingFriendList] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");

  useEffect(() => {
    if (from === "requestedFriend") {
      dispatch(pendingFriendRequestAction({ search: searchTxt })).then((res) =>
        setPendingFriendList(res.data)
      );
    }
    if (from === "friendDonor") {
      dispatch(renderFriendListAction({ search: searchTxt })).then((res) =>
        setPendingFriendList(res?.data?.friends)
      );
    }
    if (from === "userDonor") {
      dispatch(renderAllUserListAction({ search: searchTxt })).then((res) =>
        setPendingFriendList(res?.data?.findDonor)
      );
    }
  }, [searchTxt]);

  const acceptRejectRequestHandler = (id, status) => {
    const payload = {
      id,
      status,
    };

    dispatch(acceptRejectFriendRequestAction(payload)).then(() => {
      dispatch(getUserDetailsAction(userId)).then((res) => {
        setUserDetails(res.data);
      });
      dispatch(pendingFriendRequestAction({ search: "" })).then((res) =>
        setPendingFriendList(res.data)
      );
    });
  };

  const sendRequestBtnHandler = (id) => {
    if (postHeaderName === "Find Friends")
      dispatch(sendFriendRequestAction({ friendId: id })).then(() => {
        dispatch(renderAllUserListAction({ search: searchTxt })).then((res) =>
          setPendingFriendList(res?.data?.findDonor)
        );
      });
  };

  const getFriendName = (user) => {
    if (from === "requestedFriend") {
      return <h4>{user?.friendName}</h4>;
    }
    if (from === "friendDonor") {
      return <h4>{`${user?.firstName} ${user?.lastName}`}</h4>;
    }
    if (from === "userDonor") {
      return <h4>{user?.userName}</h4>;
    }
  };

  const getUserProfilePic = (user) => {
    if (from === "requestedFriend") {
      return user?.friendImage ? (
        <img
          src={mediaURL + "userProfilePic/" + user?.friendImage}
          alt="friend"
        />
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    }
    if (from === "friendDonor") {
      return user?.image ? (
        <img src={mediaURL + "userProfilePic/" + user?.image} alt="friend" />
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    }
    if (from === "userDonor") {
      return user?.userImage ? (
        <img
          src={mediaURL + "userProfilePic/" + user?.userImage}
          alt="friend"
        />
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    }
  };

  const btnText = (user) => {
    if (postHeaderName === "Find Friends") {
      if (user?.isSend === "Requested") {
        return "Requested";
      }
      return "Send Request";
    } else if (postHeaderName === "Friend Donor") {
      return (
        <>
          <div className="donorFriendButtonsGrp">
            <button></button>
            <button>
              <Telegram />
            </button>
          </div>
        </>
      );
    }
  };

  const isDisabled = (user) => {
    if (postHeaderName === "Find Friends") {
      return user?.isSend === "Requested" ? true : false;
    }
  };

  const showEmptyListMessage = () => {
    if (from === "requestedFriend") {
      return "no friend request found";
    }
    if (from === "friendDonor") {
      return "no rosi friends found";
    }
    if (from === "userDonor") {
      return "No Donor found";
    }
  };

  const unfrienddonor = (donorId) => {
    dispatch(unfriendFriendAction({ _id: donorId })).then(() => {
      dispatch(getUserDetailsAction(userId)).then((res) => {
        setUserDetails(res.data);
      });
      if (from === "friendDonor") {
        dispatch(renderFriendListAction({ search: searchTxt })).then((res) =>
          setPendingFriendList(res?.data?.friends)
        );
      }
    });
  };
  const onMessageBtnClick = (item) => {
    navigate("/chat", {
      state: {
        from: "post",
        sendToId: item?.userId,
        type: "user",
        name: item?.firstName + item?.lastName,
      },
    });
  };
  return (
    <>
      <div className="postTabing">
        <div className="postTabingHeader">
          <h4>{postHeaderName}</h4>
          {/* <button>View All</button> */}
        </div>
        <div className="postSearch">
          <div className="searchGroup">
            <input
              type="text"
              value={searchTxt}
              placeholder={
                from === "requestedFriend"
                  ? "Search For Requested Donor Friends"
                  : "Search For Rosi Friends"
              }
              onChange={(e) => setSearchTxt(e.target.value)}
            />
            <Search />
          </div>
        </div>
        <div className="scrollBarTab">
          <ScrollBar component="div">
            <div className="postScrollContent">
              {pendingFriendList?.length > 0 ? (
                pendingFriendList?.map((item, ind) => (
                  <div className="postTabingContent" key={item?._id}>
                    <div
                      className="postTabbingLeft"
                      onClick={() => navigate(`/user-profile/${item?.userId}`)}
                    >
                      <figure>{getUserProfilePic(item)}</figure>
                      <div className="postDonor">
                        {getFriendName(item)}
                        {/* <h4>{item?.friendName}</h4> */}
                        <h5>{item?.city}</h5>
                      </div>
                    </div>
                    <div className="postTabbingButton">
                      {props?.from === "userDonor" ? (
                        <button
                          className={
                            item?.isSend === "Requested"
                              ? "pendingButton"
                              : "requestButton"
                          }
                          disabled={isDisabled(item)}
                          onClick={() => sendRequestBtnHandler(item?._id)}
                        >
                          {btnText(item)}
                        </button>
                      ) : (
                        <div className="postButtonGrp">
                          {postHeaderName === "Requested Friends" ? (
                            <>
                              <button
                                onClick={() =>
                                  acceptRejectRequestHandler(item?._id, 2)
                                }
                              >
                                <img
                                  src={toAbsoluteUrl("/images/true.svg")}
                                  alt="true"
                                />
                              </button>
                              <button
                                onClick={() =>
                                  acceptRejectRequestHandler(item?._id, 3)
                                }
                              >
                                <Cancle />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                title="Message"
                                onClick={() => onMessageBtnClick(item)}
                              >
                                <Telegram />
                              </button>
                              <button
                                onClick={() => unfrienddonor(item?.userId)}
                              >
                                <img
                                  src={toAbsoluteUrl("/images/unfriend.png")}
                                  alt="true"
                                  title="Unfriend"
                                />
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <h4 className="notFound">{showEmptyListMessage()}</h4>
              )}
            </div>
          </ScrollBar>
        </div>
      </div>
    </>
  );
}

export default PostTab;
