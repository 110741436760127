/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Share from "../../components/common/Share";
import { toAbsoluteUrl, isUserLoggedIn } from "../../utils";
import Email from "../../assets/svg/email";
import Location from "../../assets/svg/location";
import Birthday from "../../assets/svg/birthday";
import Telephone from "../../assets/svg/telephone";
import Right from "../../assets/svg/right";
import "./eventDetails.scss";
import Time from "../../assets/svg/time";
import CustomModal from "../../components/common/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  eventDetailsAction,
  joinEventAction,
  updateEventStatusAction,
} from "../../actions/event";
import moment from "moment";
import ScrollBar from "react-perfect-scrollbar";
import { formatPhoneNumber } from "../../utils/reusable";
import Link from "../../assets/svg/link";
import toast from "react-hot-toast";

function EventDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const eventId = params.id;
  const navigate = useNavigate();
  const { eventDetails } = useSelector((state) => state.event);
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [args, setArgs] = useState();
  const [isBefore, setIsBefore] = useState();

  const bread = [
    {
      name: "Event Details",
      link: "/event_details",
      isActive: true,
    },
  ];

  useEffect(() => {
    if (eventDetails) setIsBefore(moment(eventDetails?.eventTime).isBefore());
  }, [eventDetails]);

  useEffect(() => {
    setArgs({
      eventId: eventDetails?._id,
      userId,
    });
  }, [eventDetails]);

  useEffect(() => {
    dispatch(eventDetailsAction({ _id: eventId }));
  }, []);

  const eventAddress = () => {
    return `${eventDetails?.eventLocation?.street}, ${eventDetails?.eventLocation?.street2
        ? eventDetails?.eventLocation?.street2 + ","
        : ""
      }
      ${eventDetails?.eventLocation?.city},
      ${eventDetails?.eventLocation?.state},
      ${eventDetails?.eventLocation?.zipcode}`;
  };

  const attendEventHandler = (status) => {
    if (eventDetails?.joinMemberId) {
      dispatch(
        updateEventStatusAction({ _id: eventDetails?.joinMemberId, status })
      ).then(() => dispatch(eventDetailsAction({ _id: eventId })));
      return;
    }
    dispatch(joinEventAction({ ...args, status })).then(() =>
      dispatch(eventDetailsAction({ _id: eventId }))
    );
  };

  const interestedBtnClickHandler = () => {
    if (!isUserLoggedIn()) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    attendEventHandler("Interested");
  };

  const goingBtnClickHandler = () => {
    if (!isUserLoggedIn()) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    attendEventHandler("Going");
    setModalIsOpen(true);
  };

  const notGoingBtnClickHandler = () => {
    if (!isUserLoggedIn()) {
      toast.error("Please signin first to use this functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    attendEventHandler("Notgoing");
  };

  return (
    <>
      <section className="eventDetail">
        <div className="container">
          <div className="breadcrumbsEventDetails">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <section className="eventDetailsWrap">
            {/* sidebar */}
            <div className="eventSidebar">
              {/* event creator */}
              <div className="eventCreator">
                <h1>Event Creator</h1>
                <div className="creatorImage">
                  <figure>
                    {eventDetails?.eventHostImage ? (
                      <img
                        src={
                          mediaURL +
                          "eventHostImages/" +
                          eventDetails?.eventHostImage
                        }
                        alt="creator"
                      ></img>
                    ) : (
                      <img
                        src={toAbsoluteUrl("/images/placeholder.png")}
                        alt="placeholder"
                      />
                    )}
                  </figure>
                </div>
                <div className="organize">
                  <span>Event By</span>
                  <h3>{eventDetails?.eventHostname}</h3>
                </div>
              </div>
              {/* attending */}
              <div className="attending">
                <h1>Members to Attend The event</h1>
                <div className="attendingCountWrap">
                  <div
                    className={`attendingCount ${eventDetails?.joinEventMemberStatus === "Interested"
                        ? "attendingCountActive"
                        : ""
                      }`}
                  >
                    <span>{eventDetails?.allMemberCount?.Interested}</span>
                    <h4>Interested</h4>
                  </div>
                  <div
                    className={`attendingCount ${eventDetails?.joinEventMemberStatus === "Going"
                        ? "attendingCountActive"
                        : ""
                      }`}
                  >
                    <span>{eventDetails?.allMemberCount?.Going}</span>
                    <h4>Going</h4>
                  </div>
                  <div
                    className={`attendingCount ${eventDetails?.joinEventMemberStatus === "Notgoing"
                        ? "attendingCountActive"
                        : ""
                      }`}
                  >
                    <span>{eventDetails?.allMemberCount?.Notgoing}</span>
                    <h4>Not Going</h4>
                  </div>
                </div>
              </div>
              {/* share*/}
              <Share />
            </div>
            {/* content */}
            <div className="eventContent">
              <div className="eventmainContent">
                <ScrollBar component="div">
                  <div className="content">
                    <div className="mainEventImage">
                      <figure>
                        {eventDetails?.eventImage ? (
                          <img
                            src={
                              mediaURL +
                              "eventImages/" +
                              eventDetails?.eventImage
                            }
                            alt="eventImage"
                          />
                        ) : (
                          <img
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt="placeholder"
                          />
                        )}
                      </figure>
                    </div>
                    {isBefore === false ? (
                      <div className="eventButtons">
                        <button
                          onClick={interestedBtnClickHandler}
                          className={
                            eventDetails?.joinEventMemberStatus === "Interested"
                              ? "selectedBtn"
                              : ""
                          }
                        >
                          Interested
                        </button>
                        <button
                          onClick={goingBtnClickHandler}
                          className={
                            eventDetails?.joinEventMemberStatus === "Going"
                              ? "selectedBtn"
                              : ""
                          }
                        >
                          Going
                        </button>
                        <button
                          onClick={notGoingBtnClickHandler}
                          className={
                            eventDetails?.joinEventMemberStatus === "Notgoing"
                              ? "selectedBtn"
                              : ""
                          }
                        >
                          Not going
                        </button>
                      </div>
                    ) : (
                      <button className="eventComplete">
                        <Birthday />
                        <span> Event Completed</span>
                      </button>
                    )}
                    <div className="mainEventContent">
                      <h2>{`Event Name: ${eventDetails?.eventName}`}</h2>
                      <ul>
                        <li>
                          <p
                           className="newline"
                            dangerouslySetInnerHTML={{ __html: eventDetails?.description }}
                          ></p>
                        </li>
                        <li>
                          <div className="addressEvent">
                            <Location />
                            {eventDetails?.eventType !== "virtual" ? (
                              <address>{eventAddress()}</address>
                            ) : (
                              <address>Virtual Event</address>
                            )}
                          </div>
                        </li>
                        {eventDetails.communityName && (
                          <li>
                            <img
                              src={toAbsoluteUrl("/images/community-leave.png")}
                              alt="community-logo"
                            />
                            <span className="communityTexts">
                              {eventDetails.communityName}
                            </span>
                          </li>
                        )}
                        {eventDetails?.charityName && (
                          <li>
                            <img
                              src={toAbsoluteUrl("/images/charity_home.png")}
                              alt="community-logo"
                            />
                            <span className="charityTexts">
                              {eventDetails.charityName}
                            </span>
                          </li>
                        )}
                        <li>
                          <div className="eventDestination">
                            <div className="dateEvent">
                              <Birthday />
                              <span>
                                {moment(eventDetails?.eventTime).format(
                                  "MM-DD-YYYY"
                                )}
                              </span>
                            </div>
                            <div className="timeEvent">
                              <Time />
                              <span>
                                {moment(eventDetails?.eventTime).format(
                                  "hh:mm A"
                                )}
                              </span>
                              To
                              <span>
                                {moment(eventDetails?.eventEndTime).format(
                                  "hh:mm A"
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="eventContact">
                            <div className="emailEvent">
                              <a
                                href={`mailto: ${eventDetails?.eventHostEmail}`}
                              >
                                <Email />
                                <span>{eventDetails?.eventHostEmail}</span>
                              </a>
                            </div>
                            {eventDetails?.eventHostPhoneNo && (
                              <div className="telEvent">
                                <a
                                  href={`tel: ${eventDetails?.eventHostPhoneNo}`}
                                  className="phnNumber"
                                >
                                  <Telephone />
                                  <span>
                                    {formatPhoneNumber(
                                      eventDetails?.eventHostPhoneNo
                                    )}
                                  </span>
                                </a>
                              </div>
                            )}
                          </div>
                        </li>
                        {eventDetails?.eventLink && (
                          <li>
                            <div className="website">
                              <Link />
                              <h4>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${eventDetails?.eventLink
                                      ?.toString()
                                      ?.indexOf("http") !== -1
                                      ? eventDetails?.eventLink
                                      : `https://${eventDetails?.eventLink}`
                                    }`}
                                >
                                  {eventDetails?.eventLink}
                                </a>
                              </h4>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </ScrollBar>
              </div>
            </div>
          </section>
        </div>
      </section>

      <CustomModal
        className="eventModal"
        isOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prev) => !prev);
        }}
      >
        <div className="eventJoined">
          <Right />
        </div>
        <h2>Thank you For joining the Event !</h2>
        <p>
          {`Event Date & time: ${moment(eventDetails?.eventTime).format(
            "MM-DD-YYYY"
          )} at ${moment(eventDetails?.eventTime).format("hh:mm A")}  
          `}
        </p>
        <p>for more detail please Review the Event Detail.</p>
        <button onClick={() => setModalIsOpen((prev) => !prev)}>Done</button>
      </CustomModal>
    </>
  );
}

export default EventDetails;
