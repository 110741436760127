/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addCharityToFav,
  removeFav,
  renderFavList,
} from "../../../actions/favourite";
import { toAbsoluteUrl } from "../../../utils";
import "./charityCard.scss";
import Heart from "../../../assets/svg/heart";
import { addToBasketAction } from "../../../actions/cart";
import { toast } from "react-hot-toast";
import Approve from "../../../assets/svg/approve";
import GoToBasket from "../../../assets/svg/goToBasket";
import Telegram from "../../../assets/svg/telegram";
import { BASKET_COUNT_S } from "../../../constants/types";

function CharityCard({ charities, from, setData, page, limit, search }) {
  const navigate = useNavigate();
  const { mediaURL } = useSelector((state) => state.metaReducer);
  const basketCount = useSelector((state) => state.metaReducer?.basketCount);
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const token = JSON.parse(localStorage.getItem("webUser"))?.data?.authToken;

  const dispatch = useDispatch();

  const [isFav, setIsFav] = useState(charities?.isFavorite);
  const [isInBasket, setIsInBasket] = useState(charities?.isBasket);
  const [args, setArgs] = useState({
    minContribuionAmount: 0,
    maxContribuionAmount: 100000000000,
    page: 1,
    limit: 12,
    typeOfCharity: "",
  });

  useEffect(() => {
    if (page && limit) {
      setArgs({ ...args, limit: limit * page });
    }
    if (search) {
      let payload = { ...args, search, isNavigator: true };
      if (
        search.replace("-", "").length === 9 &&
        /^\d+$/.test(search.replace("-", ""))
      ) {
        payload = {
          ...payload,
          taxIdSearch: search.replace("-", ""),
        };
        delete payload.search;
      }
      setArgs(payload);
    }
  }, [page, limit, search]);

  useEffect(() => {
    if (charities?.isFavorite === true) setIsFav(true);
    else setIsFav(false);
  }, [charities]);

  const handleCardClick = (e, charities) => {
    e.preventDefault();
    navigate(`/charity_details/${charities._id}`, { replace: true });
  };

  const handleBasketButtonClick = (e) => {
    e.stopPropagation();
    if (!userId) {
      toast.error("Please login to add this charity in the basket");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }
    if (charities?.isBasket) {
      navigate("/basket");
      return;
    }
    const payload = {
      userId,
      basket: [
        {
          charityId: charities?._id,
          type: "charity",
        },
      ],
    };

    dispatch(addToBasketAction(payload))
      .then(() => {
        dispatch({
          type: BASKET_COUNT_S,
          payload: basketCount + 1,
        });
        toast.success("Charity added to basket successfully.");
        setIsInBasket(true);
        if (from === "favSection") {
          dispatch(renderFavList({ userId }));
        }
      })
      .catch(() => toast.error("Something went wrong"));
  };

  const handleFavClick = (e) => {
    e.stopPropagation();
    if (!token) {
      toast.error("Please signin first to use all functionality");
      navigate(`/signin?${window.location.pathname}`);
      return;
    }

    if (from === "favSection") {
      if (!isFav) {
        const payload = { userId, charityId: charities?._id };
        dispatch(addCharityToFav(payload)).then(() => {
          setIsFav(true);
          dispatch(renderFavList({ userId }));
        });
      } else {
        const payload = { _id: userId, charityId: charities?._id };
        dispatch(removeFav(payload)).then(() => {
          dispatch(renderFavList({ userId }));
          setIsFav(false);
        });
      }
      return;
    }

    if (from === "homepage") {
      if (!isFav) {
        const payload = { userId, charityId: charities?._id };
        dispatch(addCharityToFav(payload)).then(() => setIsFav(true));
      } else {
        const payload = { _id: userId, charityId: charities?._id };
        dispatch(removeFav(payload)).then(() => setIsFav(false));
      }
      return;
    }

    if (!isFav) {
      const payload = { userId, charityId: charities?._id };
      dispatch(addCharityToFav(payload)).then(() => setIsFav(true));
    } else {
      const payload = { _id: userId, charityId: charities?._id };
      dispatch(removeFav(payload)).then(() => setIsFav(false));
    }
  };

  const onMessageBtnClick = (e) => {
    e.stopPropagation();
    navigate("/chat", {
      state: {
        from: "charity",
        sendToId: `${charities?._id}`,
        type: "charity",
        name: charities?.charityName,
      },
    });
  };

  return (
    <>
      <div
        className="charityCard"
        onClick={(e) => handleCardClick(e, charities)}
        key={charities?._id}
      >
        <figure className="imageBorder charityImage">
          {charities?.logo && charities?.logo !== "string" ? (
            <img
              src={`${mediaURL}charityLogo/${charities?.logo}`}
              alt="charities"
            />
          ) : (
            <img
              src={toAbsoluteUrl("/images/placeholder.png")}
              alt="placeholder"
            />
          )}
        </figure>
        <div className="charitynWIcon">
          <h4>{charities?.charityName}</h4>
          {charities?.isNavigator === false && (
            <figure className="charityRight">
              <Approve />
            </figure>
          )}
        </div>
        {/* <p>{charities?.brief_description}</p> */}
        <p
          className="newline"
          dangerouslySetInnerHTML={{ __html: charities?.brief_description }}
        />
        <div className="charityButtons">
          <button
            className={`charityBasket  ${
              isInBasket ? "associateCharityBasket" : ""
            }`}
            onClick={handleBasketButtonClick}
          >
            <GoToBasket />
          </button>
          <button
            className={`${
              isFav === true ? "charityHeartActive" : "charityHeart"
            }`}
            onClick={handleFavClick}
          >
            <Heart />
          </button>
          {userId && charities?.isNavigator === false && (
            <button className="telegramButton" onClick={onMessageBtnClick}>
              <Telegram />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CharityCard;
