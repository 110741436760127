/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Share from "../../components/common/Share";
import { toAbsoluteUrl } from "../../utils";
import "../Post/post.scss";
import "./user.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux";
import { getUserPostListAction } from "../../actions/post";
import PostCard from "../../components/common/Post";

const bread = [
  {
    name: "Profile",
    link: "/user-profile",
    isActive: true,
  },
];

const posting = [
  {
    id: 1,
    attendingCount: 18,
    countType: "Requested",
  },
  {
    id: 2,
    attendingCount: 4,
    countType: "Approved",
  },
  {
    id: 3,
    attendingCount: 3,
    countType: "Total Feed",
  },
];

function UserProfile() {
  const dispatch = useDispatch();
  const userId = JSON.parse(localStorage.getItem("webUser"))?.data?._id;
  const { userPostDetails } = useSelector((state) => state.post);
  const { mediaURL } = useSelector((state) => state.metaReducer);

  const [isLoading, setisLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      navigate(`/signin?${window.location.pathname}`);
    }
    dispatch(getUserPostListAction(params?.id));
  }, []);

  return (
    <>
      <section className="post">
        <div className="container">
          <div className="breadcrumbsPost">
            <Breadcrumbs list={bread} />
          </div>
        </div>
        <div className="container">
          <div className="postWrap">
            <div className="postSidebar">
              {/* account */}
              <div className="accountPost">
                <div className="postAccountDetails">
                  <figure>
                    {userPostDetails?.profileImage ? (
                      <img
                        src={
                          mediaURL +
                          "userProfilePic/" +
                          userPostDetails?.profileImage
                        }
                        alt="user"
                      />
                    ) : (
                      <img
                        src={toAbsoluteUrl("/images/placeholder.png")}
                        alt="placeholder"
                      />
                    )}
                  </figure>
                  <h3>
                    {userPostDetails?.firstName +
                      " " +
                      userPostDetails?.lastName}
                  </h3>
                </div>
                <div className="userAccountCount">
                  <div key={posting.id} className="attendingPostCount">
                    <span>{userPostDetails?.totalFrdCount}</span>
                    <h4>Total Friends</h4>
                  </div>
                  <div key={posting.id} className="attendingPostCount">
                    <span>{userPostDetails?.totalFeedCount}</span>
                    <h4>Total Feeds</h4>
                  </div>
                </div>
              </div>
              <Share />
            </div>
            <div className="postContent">
              <div className="detailsHeader">
                <h2>Posts</h2>
              </div>
              {userPostDetails?.totalFeedData?.length ? (
                userPostDetails?.totalFeedData?.map((post, ind) => (
                  <PostCard
                    post={post}
                    key={ind?._id}
                    from="userProfile"
                    isLoading={isLoading}
                    setisLoading={setisLoading}
                    isMyPost={true}
                    webUserId={params?.id}
                  />
                ))
              ) : (
                <h4 className="notFound">No Post Found</h4>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserProfile;
